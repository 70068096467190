import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useApiServer} from "../../General/ApiServer";

export const Attendance = ({isOpen}) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const {getData} = useApiServer();
    const [employees, setEmployeeList] = useState([]);
    const [times, setTimesList] = useState([]);
    const [dates, setDates] = useState([]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const formatYearMonth = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`; // Adjust day if needed
    };

    const generateFullMonthDates = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        const datesArray = [];
        for (let day = 1; day <= daysInMonth; day++) {
            datesArray.push(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
        }
        return datesArray;
    };

    const fillMissingTimes = (timesArray, datesArray, oldDatesArray) => {
        return timesArray.map(employeeTimes => {
            return datesArray.map(date => {
                const index = oldDatesArray.indexOf(date);
                return index !== -1 ? employeeTimes[index] : "--:--";
            });
        });
    };


    useEffect(() => {
        getAll();
    }, [selectedDate]);

    const getAll = () => {
        getData(`attendance/get-monthly?date=${formatYearMonth(selectedDate)}`)
            .then(r => {
                const data = r.data;

                const employees = data.map(item => ({
                    employeeId: item.employeeId,
                    employeeFullName: item.employeeFullName,
                    employeePhoto: item.employeePhoto,
                }));

                const fullMonthDates = generateFullMonthDates(selectedDate);
                const times = data.map(item => item.times);

                const filledTimes = fillMissingTimes(times, fullMonthDates, data[0].dates);

                setEmployeeList(employees);
                setTimesList(filledTimes);
                setDates(fullMonthDates);
            });
    };

    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH report__box"
        >
            <div className="home_header" style={{width: isOpen ? "80%" : "95%"}}>
                <h2>Attendance</h2>
                <div style={{display: "flex", alignItems: "center"}}>
                    <CustomDatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />
                </div>
            </div>

            <div className="home_span"></div>
            <div className="mt-4 d-flex reportBox">
                <WorkerList style={{width: isOpen ? "80%" : "95%"}}>
                    <AttendanceTable employees={employees} times={times} dates={dates}/>
                </WorkerList>
            </div>
        </div>
    );
};

// Custom styled components for DatePicker
const CustomDatePicker = styled(DatePicker)`
    width: 200px;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background-color: #f9fafb;
    color: #374151;
    text-align: center;
    cursor: pointer;

    &:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.2);
    }
`;

const WorkerList = styled.div`
    display: flex;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    overflow: hidden;
`;

export const AttendanceTable = ({employees, times, dates = []}) => {
    const [headers, setHeaders] = useState(["Full name"]);

    useEffect(() => {
        setHeaders(["Full name", ...dates]);
    }, [dates]);

    return (
        <TableContainer>
            <Table1>
                <thead>
                <TR>
                    <TH>Full name</TH>
                    {/*<THContainer>*/}
                        {headers.slice(1).map((h, index) => (
                            <TH
                                style={{writingMode: "vertical-rl", transform: "rotate(180deg)", verticalAlign: "center"}}
                                key={index}
                            >
                                {h}
                            </TH>
                        ))}
                    {/*</THContainer>*/}
                </TR>
                </thead>
                <tbody>
                {times.map((list, index) => (
                    <TRStyled
                        key={index}>
                        <TD>{employees[index]?.employeeFullName || "-- -- --"}</TD>
                        {list.map((time, timeIndex) => (
                            <TD key={timeIndex}>{time || "--:--"}</TD>
                        ))}
                    </TRStyled>
                ))}
                </tbody>
            </Table1>
        </TableContainer>
    );
};

const TableContainer = styled.div`
    display: flex;
    overflow-x: auto;
`;

const Table1 = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TR = styled.tr`
    width: max-content;
`;

const TRStyled = styled.tr`
    width: max-content;
    &:hover {
        background-color: #c8b6ec;
    }
`;

const TH = styled.th`
    padding: 6px 12px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
    text-align: center;
    white-space: nowrap;
`;

const TD = styled.td`
    padding: 6px 12px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
    white-space: nowrap;
`;

