import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {RegistrarStudentsTable} from "../../../components/ListTable/RegistratorListTable/RegistrarStudentsTable";
import {Pagination} from "@mui/material";
import "./Students.scss";
import {useApiServer} from "../../General/ApiServer";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";

export const StudentsRegistrar = ({isOpen, notifications, setAlert}) => {
    const {getData} = useApiServer();
    const [students, setStudents] = useState([]);
    const [totalStudentCount, setTotalStudentCount] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("ALL");
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const role = "ROLE_REGISTRAR";

    const sendReqForGetAll = async (pageP = page, statusP = status) => {
        getData(`student/get-all-by-status?page=${pageP - 1}&size=10&status=${statusP}`)
            .then((r) => {
                setStudents(r.data.content);
                setTotalStudentCount(r.data.totalElements);
                setTotalPageCount(r.data.totalPages);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    const searchRequest = async (text, statusR = status) => {
        await getData(`student/search/${statusR}/${text}`)
            .then((r) => {
                setStudents(r.data);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    const handleStudentId = (idP = null) => {
        if (idP) {
            setStudentId(idP);
        }
        setIsOpenModal(true);
    };
    const handlePageChange = async (e, pageR) => {
        setPage(pageR);
        await sendReqForGetAll(pageR, status);
    };

    const handleMenuChange = async (p) => {
        setStatus(p);
        await sendReqForGetAll(page, p);
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
        setApplicationId(null);
    };

    const handleSearchText = async (e) => {
        const value = e.target.value;
        if (value && value.length > 3) {
            await searchRequest(value);
        } else if (value.length === 0) {
            await sendReqForGetAll();
        }
    };

    useEffect(() => {
        sendReqForGetAll();
    }, []);

    return (
        <div style={{width: isOpen ? "81%" : "95%"}} className="containerH">
            <div className="home_header">
                <h2>Students</h2>
                <div>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            onChange={handleSearchText}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <ul style={{width: "max-content"}} className="control_list">
                <li
                    className="control_item control_link"
                    style={{
                        color: "#667085",
                        backgroundColor: status === "ALL" ? "#fff" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("ALL")}
                >
                    ALL
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: status === "R_NEW" ? "#6941C6" : "#667085",
                        backgroundColor: status === "R_NEW" ? "#F9F5FF" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("R_NEW")}
                >
                    New
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color:status === "WAITING" ? "#FF5722" : "#667085" ,
                        backgroundColor: status === "WAITING" ? "#FBE9E7" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("WAITING")}
                >
                    Waiting
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: status === "ACTION_NEEDED" ? "#9C27B0" : "#667085" ,
                        width: "max-content",
                        backgroundColor: status === "ACTION_NEEDED" ? "#F3E5F5" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("ACTION_NEEDED")}
                >
                    Action needed
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: status === "SUCCESS" ? "#006400" : "#667085" ,
                        backgroundColor: status === "SUCCESS" ? "#E6F5E6" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("SUCCESS")}
                >
                    Success
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: status === "REJECT" ? "#F44336" : "#667085" ,
                        backgroundColor: status === "REJECT" ? "#FFEBEE" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("REJECT")}
                >
                    Reject
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: status === "REPEAT" ? "#E91E63" : "#667085" ,
                        backgroundColor: status === "REPEAT" ? "#FCE4EC" : "#f9fafb",
                    }}
                    onClick={() => handleMenuChange("REPEAT")}
                >
                    Repeat
                </li>
            </ul>
            <div className="home_span"></div>

            <div className="mt-4 d-flex justify-content-between">
                <div style={{width: "100%"}}>
                    <WorkerList style={{width: isOpen ? "100%" : "100%"}}>
                        <RegistrarStudentsTable
                            students={students}
                            totalCount={totalStudentCount}
                            handleStudentId={handleStudentId}
                            role={"ROLE_REGISTER"}
                        />
                        <div className="pagination">
                            <Pagination
                                //   className={`pagination home_p ${isOpen ? "open" : "closed"}`}
                                count={totalPageCount}
                                size="large"
                                page={page}
                                onChange={handlePageChange}
                            />
                        </div>
                    </WorkerList>
                </div>
            </div>
            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={sendReqForGetAll}
                applicationId={applicationId}
                studentId={studentId}
                notifications={notifications}
                setAlert={setAlert}
            />
        </div>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /* margin-right: 32px; */
    @media (max-width: 1822px) {
        height: 63vh;
    }
    @media (max-width: 1708px) {
        height: 60vh;
    }
    @media (max-width: 1608px) {
        height: 58vh;
    }
    @media (max-width: 1518px) {
        height: 56vh;
    }
    @media (max-width: 1308px) {
        height: 47vh;
    }
`;
