import React, {useEffect, useState} from "react";
import {Card} from "../../General/Card";
import Draft from "../../../Img/Consultant-home-draft.svg";
import Complete from "../../../Img/Consultant-home-complate.svg";
import Repeat from "../../../Img/Consultant-home-repeat.svg";
import TotalUser from "../../../Img/Card-total-user.svg";
import styled from "styled-components";
import {ConsultantHomeList} from "../../../components/ListTable/ListTable";
import BasicDateCalendar from "../../../components/DateCalendar/DateCalendar";
import {useApiServer} from "../../General/ApiServer";
import "./Home.scss";
import {Pagination} from "@mui/material";
import {ModalStartWork} from "../../../components/Modal/ModalStartWork";

export const Home = ({isOpen, setAlert}) => {
    const {getData} = useApiServer();
    const [{numberStudentsOfCompleted, numberStudentsOfDraft, numberStudentsOfInRepeat, totalStudents}, setStats] = useState({});
    const [students, setStudents] = useState([]);
    const [totalStudentCount, setTotalStudentCount] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const sendReqForGetAll = async (pageR = page) => {
        getData(`student/get-all-by-status?page=${pageR - 1}&size=10&status=C_NEW`)
            .then((r) => {
                setStudents(r.data.content);
                setTotalStudentCount(r.data.totalElements);
                setTotalPageCount(r.data.totalPages);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        getData("stats/consultant")
            .then((r) => setStats(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));

        sendReqForGetAll();

        getData("attendance/check-for-today")
            .then(r => {
                if (r.data === true) {
                    setShowModal(true);
                }
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}))
    }, []);

    const onClose = () => {
        setShowModal(false);
    }

    const handlePageChange = async (event, pageP) => {
        setPage(pageP);
        await sendReqForGetAll(pageP);
    };

    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH consultant_box"
        >
            <div className="home_header">
                <h2>Dashboard</h2>
            </div>
            <div className="home_span"></div>
            <ul className="home_item">
                <Card
                    cardImg={Draft}
                    stat={{
                        id: "just",
                        name: "Draft",
                        countOfStudent: numberStudentsOfDraft,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={Complete}
                    stat={{
                        id: "just",
                        name: "Complete",
                        countOfStudent: numberStudentsOfCompleted,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={Repeat}
                    stat={{
                        id: "just",
                        name: "Repeat",
                        countOfStudent: numberStudentsOfInRepeat,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={TotalUser}
                    stat={{
                        id: "just",
                        name: "Total Student",
                        countOfStudent: totalStudents,
                    }}
                    isPin={"str"}
                />
            </ul>
            <div className="mt-4 d-flex justify-content-between">
                <div style={{width: "100%"}}>
                    <WorkerList style={{width: isOpen ? "95.5%" : "92.5%"}}>
                        <ConsultantHomeList
                            studentList={students}
                            totalCount={totalStudentCount}
                        />
                        <div className="pagination">
                            <Pagination
                                // className={`pagination home_p con ${isOpen ? "open" : "closed"}`}
                                count={totalPageCount}
                                size="large"
                                page={page}
                                onChange={handlePageChange}
                            />
                        </div>
                    </WorkerList>
                </div>
                <div
                    style={{
                        border: "1px solid var(--Gray-200, #eaecf0)",
                        borderRadius: "10px",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <BasicDateCalendar
                        slotProps={{
                            textField: {
                                InputLabelProps: {
                                    sx: {
                                        fontSize: "1rem",
                                        color: "red",
                                        backgroundColor: "red",
                                        fontWeight: 400,
                                        "&.Mui-focused": {color: "#5F5955"},
                                    },
                                },
                                variant: "filled",
                                fullWidth: true,
                                size: "small",
                                InputProps: {
                                    style: {fontWeight: 700},
                                    disableUnderline: true,
                                },
                            },
                        }}
                    />
                </div>
            </div>
            <ModalStartWork isOpen={showModal} onClose={onClose}/>
        </div>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 80vh;
    padding-bottom: 100px;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 57vh;
    }
    @media (max-width: 1708px) {
        height: 53vh;
    }
    @media (max-width: 1608px) {
        height: 52vh;
    }
    @media (max-width: 1518px) {
        height: 48vh;
    }
    @media (max-width: 1308px) {
        height: 45vh;
    }
`;
