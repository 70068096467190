import React from "react";
import styled from "styled-components";
import Offline from "../../../Img/Online.svg";
import Online from "../../../Img/Offline.svg";
import Button from "@mui/joy/Button";
import DefaultWorker from "../../../Img/DefaultWorker.png";
import {NavLink} from "react-router-dom";

export const AdminEmployeeTable = ({employee, deleteEmployeeFunc}) => {
    const header = ["Surname, Name", "Status", "Phone number", "Role", "Students", ""];

    return (
        <>
            <div className="table_header">
                <h3>Employee</h3>
                <p>{`${employee.length} employee`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                <tbody>
                {employee.map(({fullName, id, isOnline, phone, photo, role}) => (
                    <>
                        <TR>
                            <TD>
                                <span style={{display: "flex", alignItems: "center"}}>
                                    <img
                                        style={{
                                            borderRadius: "50%",
                                            width: "40px",
                                            height: "40px",
                                        }}
                                        src={
                                            photo
                                                ? `https://target-crm-backend-api.uz/api/v1/attach/open/` +
                                                photo
                                                : DefaultWorker
                                        }
                                        alt={fullName}
                                    />
                                    <strong style={{marginLeft: "30px"}}>
                                    {fullName || "-- -- --"}
                                    </strong>
                                </span>
                            </TD>
                            <TD>
                                <img src={isOnline ? Online : Offline} alt=""/>
                            </TD>
                            <TD>{phone}</TD>
                            <TD>
                                {role === "ROLE_ADMIN" && "Admin"}
                                {role === "ROLE_REGISTER" && "Register"}
                                {role === "ROLE_CONSULTANT" && "Consultant"}
                                {role === "ROLE_OPERATOR" && "Operator"}
                                {role === "" && "--/--/--"}
                            </TD>
                            <TD>
                                <NavLink
                                to={`/employee/${id}`}
                                >
                                    students
                                </NavLink>
                            </TD>
                            <TD>
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => {
                                        deleteEmployeeFunc(id);
                                    }}
                                >
                                    <svg
                                        style={{cursor: "pointer"}}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                            </TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    padding: 16px 24px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
`;
