import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReportTable} from "../../../components/ListTable/ListTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Report.scss";
import {format} from 'date-fns';
import {useApiServer} from "../ApiServer";
import axios from "axios";
import {useAuth} from "../../../Hooks/useAuth";

export const Report = ({isOpen, setAlert}) => {
    const {getData, postData} = useApiServer();
    // select role
    const [selectedRole, setSelectedRole] = useState("ROLE_OPERATOR")
    // employee
    const [employees, setEmployees] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    // students
    const [students, setStudents] = useState([])
    // calendar
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // role
    const [role, setRole] = useState(null)
    // token
    const {token} = useAuth()


    const getEmployeeByRole = (byRole = selectedRole) => {
        getData(`admin/all?role=${byRole}`)
            .then(r => setEmployees(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    }

    useEffect(() => {
        getEmployeeByRole()
        setRole(localStorage.getItem("role") || null)
    }, []);

    const test = () => {
        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

        if (formattedStartDate == null || formattedEndDate == null) {
            return
        }
        const data =
            {
                employeeId: selectedEmployee || null,
                startDate: formattedStartDate,
                endDate: formattedEndDate
            };

        postData("report/get", data)
            .then(r => setStudents(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));

    }

    const handleSelect = async (e, prop) => {
        if (prop === "role") {
            setSelectedRole(e.target.value)
            setSelectedEmployee(null)
            await getEmployeeByRole(e.target.value)
        } else if (prop === "employee") {
            setSelectedEmployee(e.target.value)
        }
        setStudents([])
    }

    const setDateRange = (update) => {
        setStartDate(update[0]);
        setEndDate(update[1]);
    };

    const downloadData = async () => {

        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

        if (formattedStartDate == null || formattedEndDate == null) {
            return
        }

        try {
            const data =
                {
                    employeeId: selectedEmployee || null,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate
                };
            const response =
                await axios.post(`https://target-crm-backend-api.uz/api/v1/report/pdf`, data, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                    }
                })
                    .catch(() => setAlert({status: "error", message: "Not found data"}));

            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '')
                : 'report.pdf';

            const url = URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        } catch (err) {
            setAlert({status: "error", message: "Not found data"})
        }
    };


    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH report__box"
        >
            <div className="home_header">
                <h2>Report</h2>
                <div style={{display: "flex"}}>
                    <select hidden={role !== "ROLE_ADMIN"} style={{outline: "none"}}
                            onChange={(e) => handleSelect(e, "role")}>
                        <SelectOption selected={selectedRole === "ROLE_OPERATOR"} value={"ROLE_OPERATOR"}>
                            <span>Operator</span>
                        </SelectOption>
                        <SelectOption selected={selectedRole === "ROLE_CONSULTANT"} value={"ROLE_CONSULTANT"}>
                            <span>Consultant</span>
                        </SelectOption>
                        <SelectOption selected={selectedRole === "ROLE_REGISTER"} value={"ROLE_REGISTER"}>
                            <span>Registrar</span>
                        </SelectOption>
                    </select>
                    <select hidden={role !== "ROLE_ADMIN"} style={{outline: "none"}}
                            onChange={(e) => handleSelect(e, "employee")}>
                        <SelectOption disabled selected={selectedEmployee == null}>
                            <span>Select</span>
                        </SelectOption>
                        {
                            employees.map((employee) => (
                                    <SelectOption value={employee.id} key={employee.id}>
                                        <span>{employee.fullName || "-- -- --"}</span>
                                    </SelectOption>
                                )
                            )

                        }
                    </select>
                    <div>
                        <DatePicker
                            className="employe_header_button-white"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            placeholderText="Select a date range"
                        />
                    </div>
                    <button onClick={() => test()} className="data_ok" type="button">OK</button>
                    <button
                        onClick={() => downloadData()}
                        style={{width: "170px", display: "flex"}}
                        className="employe_header_button-white button-hover me-0"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                stroke="#344054"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        Download
                    </button>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between reportBox">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <ReportTable students={students}/>
                </WorkerList>
            </div>
        </div>
    );
};

const WorkerList = styled.div`
    width: 190vh;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
`;

const SelectOption = styled.option`
    padding: 10px !important;
    display: block;
    margin-bottom: 10px;
`;
