import { useEffect, useState } from "react";

export const StudentStatus = ({ type, role }) => {
    const [color, setColor] = useState("green");
    const [borderColor, setBorderColor] = useState("red");
    const [bgColor, setBgColor] = useState("blue");
    const [name, setName] = useState();

    useEffect(() => {
        /*switch (type) {
            case "PAID": {
                setColor("#3F9772");
                setName("Paid");
                setBorderColor("#17B26A");
                setBgColor("#ECFDF3");
            }break
            case "DEBT": {
                setColor("#C6574E");
                setName("Debt");
                setBorderColor("#F04438");
                setBgColor("#FEF3F2");
            }break
            case "O_NEW": {
                setColor("#6941C6");
                setName("New");
                setBorderColor("#E9D7FE");
                setBgColor("#F9F5FF");

            }break;
            case "C_NEW": {
                if (role === "ROLE_CONSULTANT") {
                    setColor("#6941C6");
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#344054");
                    setName("Draft");
                    setBorderColor("#D0D5DD");
                    setBgColor("#FFFFFF");
                }
                break;
            }
            case "DRAFT": {
                setColor("#344054");
                setName("Draft");
                setBorderColor("#D0D5DD");
                setBgColor("#FFFFFF");
                break;
            }
            case "COMPLETED": {
                setColor("#067647");
                setName("Complete");
                setBorderColor("#ABEFC6");
                setBgColor("#ECFDF3");
                break;
            }
            case "REPEAT": {
                setColor("#b4185e");
                setName("Repeat");
                setBorderColor("#fed7fc");
                setBgColor("#fff5ff");
                break;
            }
            case "R_NEW": {
                if (role === "ROLE_REGISTER") {
                    setColor("#6941C6");
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#067647");
                    setName("Complete");
                    setBorderColor("#ABEFC6");
                    setBgColor("#ECFDF3");
                }
                break;
            }
            case "WAITING": {
                setColor("#B54708");
                setName("Waiting");
                setBorderColor("#FEDF89");
                setBgColor("#FFFAEB");
                break;
            }
            case "ACTION_NEEDED": {
                setColor("#440676");
                setName("Action needed");
                setBorderColor("#c6abef");
                setBgColor("#f3ecfd");
                break;
            }
            case "SUCCESS": {
                setColor("#3538CD");
                setName("Success");
                setBorderColor("#C7D7FE");
                setBgColor("#EEF4FF");
                break;
            }
            case "REJECT": {
                setColor("#B42318");
                setName("Reject");
                setBorderColor("#FECDCA");
                setBgColor("#FEF3F2");
                break;
            }
            default:
                break;
        }*/

        /*switch (type) {
            case "PAID": {
                setColor("#3F9772"); // Green
                setName("Paid");
                setBorderColor("#17B26A");
                setBgColor("#ECFDF3");
            } break;
            case "DEBT": {
                setColor("#C6574E"); // Red
                setName("Debt");
                setBorderColor("#F04438");
                setBgColor("#FEF3F2");
            } break;
            case "O_NEW": {
                setColor("#6941C6"); // Purple
                setName("New");
                setBorderColor("#E9D7FE");
                setBgColor("#F9F5FF");
            } break;
            case "C_NEW": {
                if (role === "ROLE_CONSULTANT") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#344054"); // Gray
                    setName("Draft");
                    setBorderColor("#D0D5DD");
                    setBgColor("#FFFFFF");
                }
                break;
            }
            case "DRAFT": {
                setColor("#FF9800"); // Bright Orange
                setName("Draft");
                setBorderColor("#FFC107");
                setBgColor("#FFF3E0");
                break;
            }
            case "COMPLETED": {
                setColor("#4CAF50"); // Bright Green
                setName("Complete");
                setBorderColor("#66BB6A");
                setBgColor("#E8F5E9");
                break;
            }
            case "REPEAT": {
                setColor("#E91E63"); // Bright Pink
                setName("Repeat");
                setBorderColor("#F06292");
                setBgColor("#FCE4EC");
                break;
            }
            case "R_NEW": {
                if (role === "ROLE_REGISTER") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#4CAF50"); // Bright Green
                    setName("Complete");
                    setBorderColor("#66BB6A");
                    setBgColor("#E8F5E9");
                }
                break;
            }
            case "WAITING": {
                setColor("#FF5722"); // Bright Orange
                setName("Waiting");
                setBorderColor("#FF8A65");
                setBgColor("#FBE9E7");
                break;
            }
            case "ACTION_NEEDED": {
                setColor("#9C27B0"); // Bright Purple
                setName("Action needed");
                setBorderColor("#BA68C8");
                setBgColor("#F3E5F5");
                break;
            }
            case "SUCCESS": {
                setColor("#2196F3"); // Bright Blue
                setName("Success");
                setBorderColor("#64B5F6");
                setBgColor("#E3F2FD");
                break;
            }
            case "REJECT": {
                setColor("#F44336"); // Bright Red
                setName("Reject");
                setBorderColor("#E57373");
                setBgColor("#FFEBEE");
                break;
            }
            default:
                break;
        }*/
        /*switch (type) {
            case "PAID": {
                setColor("#3F9772"); // Green
                setName("Paid");
                setBorderColor("#17B26A");
                setBgColor("#ECFDF3");
            } break;
            case "DEBT": {
                setColor("#C6574E"); // Red
                setName("Debt");
                setBorderColor("#F04438");
                setBgColor("#FEF3F2");
            } break;
            case "O_NEW": {
                setColor("#6941C6"); // Purple
                setName("New");
                setBorderColor("#E9D7FE");
                setBgColor("#F9F5FF");
            } break;
            case "C_NEW": {
                if (role === "ROLE_CONSULTANT") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#344054"); // Gray
                    setName("Draft");
                    setBorderColor("#D0D5DD");
                    setBgColor("#FFFFFF");
                }
                break;
            }
            case "DRAFT": {
                setColor("#FF9800"); // Bright Orange
                setName("Draft");
                setBorderColor("#FFC107");
                setBgColor("#FFF3E0");
                break;
            }
            case "COMPLETED": {
                setColor("#4CAF50"); // Bright Green
                setName("Complete");
                setBorderColor("#66BB6A");
                setBgColor("#E8F5E9");
                break;
            }
            case "REPEAT": {
                setColor("#E91E63"); // Bright Pink
                setName("Repeat");
                setBorderColor("#F06292");
                setBgColor("#FCE4EC");
                break;
            }
            case "R_NEW": {
                if (role === "ROLE_REGISTER") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#4CAF50"); // Bright Green
                    setName("Complete");
                    setBorderColor("#66BB6A");
                    setBgColor("#E8F5E9");
                }
                break;
            }
            case "WAITING": {
                setColor("#FF5722"); // Bright Orange
                setName("Waiting");
                setBorderColor("#FF8A65");
                setBgColor("#FBE9E7");
                break;
            }
            case "ACTION_NEEDED": {
                setColor("#9C27B0"); // Bright Purple
                setName("Action needed");
                setBorderColor("#BA68C8");
                setBgColor("#F3E5F5");
                break;
            }
            case "SUCCESS": {
                setColor("#388E3C"); // Darker Green
                setName("Success");
                setBorderColor("#81C784");
                setBgColor("#E8F5E9");
                break;
            }
            case "REJECT": {
                setColor("#F44336"); // Bright Red
                setName("Reject");
                setBorderColor("#E57373");
                setBgColor("#FFEBEE");
                break;
            }
            default:
                break;
        }*/

        switch (type) {
            case "PHONE_NOT_VALID": {
                setColor("#D32F2F"); // Dark Red
                setName("Phone not valid");
                setBorderColor("#B71C1C");
                setBgColor("#FFCDD2");
            } break;
            case "NOT_SEND": {
                setColor("#F57C00"); // Orange
                setName("Not send");
                setBorderColor("#EF6C00");
                setBgColor("#FFE0B2");
            } break;
            case "SEND": {
                setColor("#0288D1"); // Blue
                setName("Send");
                setBorderColor("#0277BD");
                setBgColor("#B3E5FC");
            } break;
            case "PAID": {
                setColor("#3F9772"); // Green
                setName("Paid");
                setBorderColor("#17B26A");
                setBgColor("#ECFDF3");
            } break;
            case "DEBT": {
                setColor("#C6574E"); // Red
                setName("Debt");
                setBorderColor("#F04438");
                setBgColor("#FEF3F2");
            } break;
            case "O_NEW": {
                setColor("#6941C6"); // Purple
                setName("New");
                setBorderColor("#E9D7FE");
                setBgColor("#F9F5FF");
            } break;
            case "C_NEW": {
                if (role === "ROLE_CONSULTANT") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#344054"); // Gray
                    setName("Draft");
                    setBorderColor("#D0D5DD");
                    setBgColor("#FFFFFF");
                }
                break;
            }
            case "DRAFT": {
                setColor("#FF9800"); // Bright Orange
                setName("Draft");
                setBorderColor("#FFC107");
                setBgColor("#FFF3E0");
                break;
            }
            case "COMPLETED": {
                setColor("#00FF7F"); // Spring Green
                setName("Complete");
                setBorderColor("#00FA9A");
                setBgColor("#E0FFE8");
                break;
            }
            case "REPEAT": {
                setColor("#E91E63"); // Bright Pink
                setName("Repeat");
                setBorderColor("#F06292");
                setBgColor("#FCE4EC");
                break;
            }
            case "R_NEW": {
                if (role === "ROLE_REGISTER") {
                    setColor("#6941C6"); // Purple
                    setName("New");
                    setBorderColor("#E9D7FE");
                    setBgColor("#F9F5FF");
                } else {
                    setColor("#00FF7F"); // Spring Green
                    setName("Complete");
                    setBorderColor("#00FA9A");
                    setBgColor("#E0FFE8");
                }
                break;
            }
            case "WAITING": {
                setColor("#FF5722"); // Bright Orange
                setName("Waiting");
                setBorderColor("#FF8A65");
                setBgColor("#FBE9E7");
                break;
            }
            case "ACTION_NEEDED": {
                setColor("#9C27B0"); // Bright Purple
                setName("Action needed");
                setBorderColor("#BA68C8");
                    setBgColor("#F3E5F5");
                break;
            }
            case "SUCCESS": {
                setColor("#006400"); // Dark Green
                setName("Success");
                setBorderColor("#228B22");
                setBgColor("#E6F5E6");
                break;
            }
            case "REJECT": {
                setColor("#F44336"); // Bright Red
                setName("Reject");
                setBorderColor("#E57373");
                setBgColor("#FFEBEE");
                break;
            }
            default:
                break;
        }



    }, [type, role]);

    return (
        <div
            style={{
                color: color,
                borderColor: borderColor,
                backgroundColor: bgColor,
                borderStyle: "solid",
                display: "flex",
                padding: "2px 10px",
                alignItems: "center",
                borderRadius: "13px",
                borderWidth: "1px", // Adjust this based on how thick you want the border
                fontSize: "14px",
                width: "max-content"
            }}
        >
            <div
                style={{
                    width: "7px",
                    height: "7px",
                    backgroundColor: color,
                    marginRight: "8px",
                    borderRadius: "50%", // If you want a circular color indicator
                }}
            />
            <p>{name}</p>
        </div>
    );
};
