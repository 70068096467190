import React, {useEffect, useState} from "react";
import "./settings.scss";
import SettingPro from "../../../Img/settingPro.png";
import Download from "../../../Img/upload.svg";
import DefaultWorkerImg from "../../../Img/DefaultWorker.png";
import {useApiServer} from "../ApiServer";

export const Settings = ({isOpen, setAdminP, setAlert}) => {
    const {putData, postFile, putFile, getData} = useApiServer();
    const [{fullName: fullName1, phone: phone1, photo, role}, setAdmin] = useState({});
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        requestApiGetInfo().then(() => {
        });
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const requestApiUploadFile = async () => {

        if (photo) {
            return await putFile(selectedFile, photo, photo)
        } else {
            return await postFile(selectedFile, cleanText(fullName))
        }
    };

    const cleanText = (str) => {
        const regex = /[^a-zA-Z0-9\s]/g; // Regular expression to match non-alphanumeric characters
        return str.replace(regex, '').replace(/\s/g, '_'); // Replace spaces with underscores
    };

    const requestApiGetInfo = async () => {
        await getData(`admin/`)
            .then((response) => {
                const adminRes = response.data;
                setAdmin(adminRes);
                setAdminP(adminRes)
                setFullName(adminRes.fullName);
                setPhone(adminRes.phone);
                setSelectedFileUrl(adminRes.photo ? "https://target-crm-backend-api.uz/api/v1/attach/open/" + adminRes.photo : null);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    const onSave = async (event) => {
        event.preventDefault();

        if (selectedFile) {
            await requestApiUploadFile().then(r => {
                const data = {
                    fullName: fullName,
                    phone: phone,
                    imgUrl: r
                };
                putData("admin/update", data)
                    .then(() => setAlert({status: "success", message: "Updated successfully!"}))
                    .catch(() => setAlert({status: "error", message: "Not updated"}));
            })
            await requestApiGetInfo()
        } else {
            const data = {
                fullName: fullName,
                phone: phone,
                imgUrl: photo
            };
            await putData("admin/update", data)
                .then(() => setAlert({status: "success", message: "Updated successfully!"}))
                .catch(() => setAlert({status: "error", message: "Not updated"}));

            await requestApiGetInfo()
        }

        btnCancel("profile");
        await requestApiGetInfo()
    };

    const onSavePassword = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Password is not same");
            return;
        }

        putData("admin/change-password", {current: oldPassword, newPasswd: newPassword,})
            .then(() => setAlert({status: "success", message: "Password changed successfully!"}))
            .catch(() => setAlert({status: "error", message: "Password not changed"}));

        btnCancel("password");
        await requestApiGetInfo()
    };

    const btnCancel = (prop) => {
        if (prop === "profile") {
            setFullName(fullName1);
            setPhone(phone1);
            setSelectedFileUrl(photo);
            setSelectedFile(null)
        }

        if (prop === "password") {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
        }
    };

    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH settingsBox"
        >
            <img className="settings_header_img" src={SettingPro} alt=""/>
            <div className="settings_header">
                <img
                    className="settings_header_person"
                    src={selectedFileUrl || photo || DefaultWorkerImg}
                    alt=""
                />
                <div className="settings_header_bottom">
                    <h3 className="settings_header_title">{fullName1}</h3>
                    <p className="settings_header_text">
                        {role === "ROLE_ADMIN" && "Admin"}
                        {role === "ROLE_REGISTER" && "Registrar"}
                        {role === "ROLE_CONSULTANT" && "Consultant"}
                        {role === "ROLE_OPERATOR" && "Operator"}
                        {role === "" && "-- -- --"}
                    </p>
                </div>
            </div>
            <div className="setting_person_info">
                <div className="setting_person_info_left">
                    <p className="setting_person_info_left_title">Personal info</p>
                    <p className="setting_person_info_left_text">
                        Update your photo and personal details.
                    </p>
                </div>
                <form onSubmit={onSave} className="setting_person_form">
                    <div className="setting_person_form_top">
                        <label>
                            <p>Full name</p>
                            <input
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                type="text"
                            />
                        </label>
                        <label>
                            <p>Phone</p>
                            <input
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                type="text"
                            />
                        </label>
                    </div>
                    <div className="setting_person_form_center">
                        <img
                            src={selectedFileUrl || photo || DefaultWorkerImg}
                            alt="Uploaded file preview"
                        />
                        <label>
                            <input
                                className="visually-hidden"
                                type="file"
                                onChange={handleFileChange}
                            />
                            {selectedFile ? (
                                <p>UPLOADED</p>
                            ) : (
                                <>
                                    <img
                                        className="setting_download_img"
                                        src={Download}
                                        alt="Upload placeholder"
                                    />
                                    <p className="settings_download_ps">
                                        Click to upload or drag and drop
                                    </p>
                                    <p className="settings_download_p">
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </p>
                                </>
                            )}
                        </label>
                    </div>
                    <span className="setting_person_form_bottom">
            <button type="button" onClick={() => btnCancel("profile")}>
              Cancel
            </button>
            <button type="submit">Save changes</button>
          </span>
                </form>
            </div>
            <div className="setting_bottom_password">
                <div className="home_span"></div>
                <div className="setting_password">
                    <h2 className="setting_password_title">Password</h2>
                    <p className="setting_setting_text">
                        Please enter your current password to change your password.
                    </p>
                </div>
                <div className="home_span"></div>
                <div className="setting_password_text">
                    <p className="setting_current_password_text">Current password</p>
                    <input
                        placeholder="Current password"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div className="home_span"></div>
                <div className="setting_password_text">
                    <p className="setting_current_password_text">New password</p>
                    <div className="setting_new_password_div">
                        <input
                            placeholder="New password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <p className="setting_new_password_div_text">
                            Your new password must be more than 8 characters.
                        </p>
                    </div>
                </div>
                <div className="home_span"></div>
                <div className="setting_password_text">
                    <p className="setting_current_password_text">Confirm new password</p>
                    <input
                        placeholder="Confirm password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="home_span"></div>
                <span className="setting_person_form_bottom">
          <button type="button" onClick={() => btnCancel("password")}>
            Cancel
          </button>
          <button onClick={(e) => onSavePassword(e)} type="submit">
            Save changes
          </button>
        </span>
            </div>
        </div>
    );
};
