import axios from 'axios';
import {useAuth} from '../../Hooks/useAuth';

const baseUrl = 'https://target-crm-backend-api.uz/api/v1/';

export const useApiServer = () => {
    const {token} = useAuth();

    const getData = async (url) => {

        return await axios.get(`${baseUrl}${url}`, {
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })

    };

    const postData = async (url, body) => {
        return await axios.post(
            `${baseUrl}${url}`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    };

    const putData = async (url, body) => {
        return await axios.put(
            `${baseUrl}${url}`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    const delData = async (url) => {
        return await axios.delete(
            `${baseUrl}${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    const postFile = async (file, fileName) => {
        const data = new FormData();
        data.append("file", file);

        try {
            const response = await axios.post(
                `https://target-crm-backend-api.uz/api/v1/attach/upload/${fileName}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 60000,
                }
            );
            return response.data; // Adjust based on the actual response structure
        } catch (error) {
            console.error(error);
            throw new Error("File upload failed");
        }
    };

    const putFile = async (file, fileName, oldName) => {
        const data = new FormData();
        data.append("file", file);

        try {
            const response = await axios.put(
                `https://target-crm-backend-api.uz/api/v1/attach/update/${fileName}/${oldName}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    // timeout: 60000,
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("File upload failed");
        }
    };

    const downloadData = async (value) => {
        try {
            const response = await axios.get(`https://target-crm-backend-api.uz/api/v1/attach/download/${value}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token.token}`,
                }
            });

            const url = URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = value;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        } catch (err) {
            console.error(err);
        }
    };

    return {getData, postData, putData, delData, postFile, putFile, downloadData};
};
