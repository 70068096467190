import DefaultWorker from "../../../Img/DefaultWorker.png";
import styled from "styled-components";

export const AdminEmployeeTopTable = ({employeeList, headerName}) => {

    const header = ["Surname, Name", "Phone number"];
    return (
        <>
            <div className="table_header">
                <h3>{headerName}</h3>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                <tbody>
                {employeeList.map(({fullName, phone, photo}) => (
                    <>
                        {employeeList.length === 0 ? (
                            <TR>
                                <TD style={{borderBottom: "none", padding: "26px 24px"}}>
                                    <p>No information found</p>
                                </TD>
                            </TR>
                        ) : (
                            <TR>
                                <TD>
                                    <span style={{display: "flex", alignItems: "center"}}>
                                        <img
                                            style={{borderRadius: "50%", width: "40px", height: "40px", }}
                                            src={photo ? `https://target-crm-backend-api.uz/api/v1/attach/open/` + photo : DefaultWorker}
                                            alt={fullName}
                                        />
                                        <strong style={{marginLeft: "30px"}}>
                                            {fullName || "-- -- --"}
                                        </strong>
                                    </span>
                                </TD>
                                <TD>
                                    {phone}
                                </TD>
                            </TR>
                        )}
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    padding: 16px 24px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
`;
