import {useApiServer} from "../ApiServer";
import React, {useEffect, useState} from "react";
import {FeedbackList} from "../../../components/ListTable/ListTable";
import Pagination from "@mui/material/Pagination";
import StudentInfoModal from "../StudentInfoModal/StudentInfoModal";
import styled from "styled-components";

export const Feedback = ({isOpen, setExistsFeedback, role, setAlert}) => {
    const {getData, postData} = useApiServer();
    const [listFeedback, setListFeedback] = useState([])
    const [totalElementsF, setTotalElementsF] = useState(0);
    const [size] = useState(10);
    const [page, setPage] = useState(1);
    const [studentId, setStudentId] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handlePageChange = (value) => {
        setPage(value);
        getAllData(value, size);
    };

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const fakeFunc = () => {
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
    };

    const getAllData = (pageReq = 1, sizeReq = 10) => {

        getData(`feedback?page=${pageReq - 1}&size=${sizeReq}`)
            .then((r) => {
                setListFeedback(r.data.content)
                setTotalElementsF(r.data.totalElements);
                markAsReadFeedback()
            })
            .catch(() => {
                setListFeedback([])
                setTotalElementsF(0)
                setAlert({status: "error", message: "Not found data"})
            });

    };

    const markAsReadFeedback = () => {
        postData("feedback/read")
            .then(() => setExistsFeedback(false))
            .catch(() => setAlert({status: "error", message: "Not found data"}))

    }

    useEffect(() => {
        getAllData()
    }, []);

    return (
        <>
            <div
                style={{width: isOpen ? "100%" : "100%"}}
                className="containerH control__box"
            >
                <div className="home_header">
                    <h2>Feedback</h2>
                </div>
                <div className="home_span"></div>
                <div className="mt-4 d-flex justify-content-between control_table">
                    <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                        <FeedbackList
                            feedbackList={listFeedback}
                            totalElements={totalElementsF}
                            openModalFunc={handleStudentId}
                        />
                        <div className="pagination">
                            <Pagination
                                count={Math.ceil(totalElementsF / size)}
                                size="large"
                                page={page}
                                onChange={(e, value) => handlePageChange(value)}
                            />
                        </div>
                    </WorkerList>
                </div>
                <StudentInfoModal
                    modal={isOpenModal}
                    setModal={closeModalFunc}
                    role={role}
                    getAll={fakeFunc()}
                    applicationId={null}
                    studentId={studentId}
                    notifications={[]}
                />
            </div>

        </>
    )
        ;
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /* margin-right: 32px; */
    @media (max-width: 1822px) {
        height: 63vh;
    }
    @media (max-width: 1708px) {
        height: 60vh;
    }
    @media (max-width: 1608px) {
        height: 55vh;
    }
    @media (max-width: 1508px) {
        height: 50vh;
    }
    @media (max-width: 1308px) {
        height: 46vh;
    }
`;
