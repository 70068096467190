import React, {useEffect, useState} from "react";
import Sun from "../../Img/sun.svg";
import "./sticky.css";

export const Sticky = ({ isOpen }) => {
  const [darkMode, setDarkMode] = useState(false);

  const ActiveMode = async () => {
    const newMode = !darkMode;
    setDarkMode(newMode);

    if (newMode) {
      document.body.classList.add("dark__mode");
      document.body.classList.remove("light__mode");
      await localStorage.setItem("Theme", "dark__mode");
    } else {
      document.body.classList.add("light__mode");
      document.body.classList.remove("dark__mode");
      await localStorage.setItem("Theme", "light__mode");
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("Theme");
    if (storedTheme === "light__mode") {
      document.body.classList.add("light__mode");
      setDarkMode(false);
    } else if (storedTheme === "dark__mode") {
      document.body.classList.add("dark__mode");
      setDarkMode(true);
    }
  }, []);
  return (
    <>
      <div className="so__sticky">
        <div className="switch-checkbox">
          {localStorage.getItem("Theme") === "dark__mode" ? (
            <>
              <div className="modeBox" onClick={ActiveMode}>
                <img src={Sun}  alt={"Logo sun"}/>
                <p
                  className="mode_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Light mode
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="modeBox" onClick={ActiveMode}>
                <img src={Sun} alt={"Logo sun"} />
                <p
                  className="mode_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Dark mode
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
