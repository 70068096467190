import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import errorImg from "../../../Img/404-error.png";

export const Error = () => {
  const navigate = useNavigate();

const handleMainPage = () => {
  navigate("/");
};

const handleBackPage = () => {
  navigate(-1);
};
  return (
    <Div className="d-flex">
      <Wrapper>
        <ErrorTextTop>404 error</ErrorTextTop>
        <ErrorTitle>Page not found</ErrorTitle>
        <ErrorText>Sorry, the page you were looking for was not found.</ErrorText>
        <ButtonDiv>
          <ButtonBack onClick={handleBackPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="me-2 align-items-center"
            >
              <path
                d="M15.8333 10.0001H4.16663M4.16663 10.0001L9.99996 15.8334M4.16663 10.0001L9.99996 4.16675"
                stroke="#344054"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Go back
          </ButtonBack>
          <ButtonMain className="ms-3" onClick={handleMainPage}>Main page</ButtonMain>
        </ButtonDiv>
      </Wrapper>
      <Left>
        <Img src={errorImg} />
      </Left>
    </Div>
  );
};

const Div = styled.div`
  margin: 0 auto;
  height: 100vh;
  width: 100vw;
  background: #fff;
`;
const Left = styled.div`
  width: 50%;
  height: 100vh;
`;
const Wrapper = styled.div`
  margin: auto;
  padding: 0 20px;
  height: 55%;
  width: 592px;
  align-items: center;
`;
const ErrorTextTop = styled.p`
  color: #6941c6;
  font-family: Inter,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;
const ErrorTitle = styled.h2`
  color: #101828;
  font-family: Inter,serif;
  font-size: 60px;
  margin-top: 5px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
`;
const ErrorText = styled.p`
  padding: 0;
  margin: 18px 0 0;
  width: 480px;
  color: #475467;
  font-family: Inter,serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
`;
const ButtonDiv = styled.div`
  display: flex;
  margin-top: 48px;
`;
const ButtonBack = styled.button`
  padding: 18px;
  align-items: center;
  color: #344054;
  font-family: Inter,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  border: 1px solid
    #d0d5dd;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  &:hover, :active{
    box-shadow: 0 0 0 4px rgba(158, 119, 237, 0.24), 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
`;
const ButtonMain = styled.button`
    padding: 18px;
    color: #fff;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    border-radius: 8px;
    border: 1px solid #7f56d9;
    background: #7f56d9;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    &:hover, :active {
        box-shadow: 0 0 0 4px rgba(158, 119, 237, 0.24), 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
