// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDmLvx8nD6Su4yGdYHYFk7XIrw4ORiMgOA",
    authDomain: "target-3b1ff.firebaseapp.com",
    projectId: "target-3b1ff",
    storageBucket: "target-3b1ff.appspot.com",
    messagingSenderId: "264777985871",
    appId: "1:264777985871:web:e9b69d402fb2a9ea149f3a",
    measurementId: "G-QZ1WRFCK3D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);