import React, {useEffect, useState} from "react";
import "./Home.scss";
import {Card} from "../../General/Card";
import DebtorImg from "../../../Img/Card-deptors.svg";
import PayerImg from "../../../Img/Card-payers.svg";
import DebtImg from "../../../Img/Card-debt.svg";
import TotalImg from "../../../Img/Card-total.svg";
import styled from "styled-components";
import {AdminUserList} from "../../../components/ListTable/ListTable";
import BasicDateCalendar from "../../../components/DateCalendar/DateCalendar";
import {useApiServer} from "../../General/ApiServer";

export const Home = ({isOpen, setAlert}) => {
    const {getData} = useApiServer();

    const [employeeList, setEmployeeList] = useState([]);
    const [stats, setStats] = useState({
        numberOfDebtors: 0,
        numberOfPayers: 0,
        totalDebt: 0.0,
        totalPaid: 0.0,
    });

    useEffect(() => {
        getData("admin/get-all")
            .then((r) => setEmployeeList(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));

        getData("stats/admin")
            .then((r) => setStats(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    }, []);

    useEffect(() => {
        const sendRequest = () => {
            getData('admin/get-all')
                .then(r => setEmployeeList(r.data))
                .catch(() => setAlert({status: "error", message: "Not found data"}));
        };

        const intervalId = setInterval(sendRequest, 5000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Dashboard</h2>
            </div>
            <div className="home_span"></div>
            <ul className="home_item">
                <Card
                    stat={{
                        id: "just",
                        name: "Number of debtors",
                        countOfStudent: stats.numberOfDebtors,
                    }}
                    isPin={"str"}
                    cardImg={DebtorImg}
                />
                <Card
                    stat={{
                        id: "just",
                        name: "Number of payers",
                        countOfStudent: stats.numberOfPayers,
                    }}
                    isPin={"str"}
                    cardImg={PayerImg}
                />
                <Card
                    stat={{
                        id: "just",
                        name: "Total debt",
                        countOfStudent: stats.totalDebt,
                    }}
                    isPin={"str"}
                    cardImg={DebtImg}
                />
                <Card
                    stat={{
                        id: "just",
                        name: "Total paid",
                        countOfStudent: stats.totalPaid,
                    }}
                    isPin={"str"}
                    cardImg={TotalImg}
                />
            </ul>
            <div className="tableMOde mt-4 d-flex justify-content-between admin_b">
                <div style={{width: "100%"}}>
                    <WorkerList style={{width: isOpen ? "95.5%" : "95.5%"}}>
                        <AdminUserList employeeList={employeeList}/>
                    </WorkerList>
                </div>
                <div
                    className="cal"
                    style={{
                        border: "1px solid var(--Gray-200, #eaecf0)",
                        borderRadius: "10px",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <BasicDateCalendar
                        slotProps={{
                            textField: {
                                InputLabelProps: {
                                    sx: {
                                        fontSize: "1rem",
                                        color: "red",
                                        backgroundColor: "red",
                                        fontWeight: 400,
                                        "&.Mui-focused": {color: "#5F5955"},
                                    },
                                },
                                variant: "filled",
                                // required: item?.is_required,
                                fullWidth: true,
                                size: "small",
                                InputProps: {
                                    style: {fontWeight: 700},
                                    disableUnderline: true,
                                    sx: {
                                        border: "1px solid black",
                                        borderRadius: "4px",
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const WorkerList = styled.div`
    width: 130vh;
    height: 55vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    margin-right: 32px;

    @media (max-width: 1822px) {
        height: 60vh;
    }
    @media (max-width: 1702px) {
        height: 54vh;
    }

    @media (max-width: 1508px) {
        height: 49vh;
    }
`;
