import React, { useEffect, useRef} from "react";
import "./modal.scss";
import "./modalJ.css";

export const ModalAll = ({ modal, closeModalP, children, setClearInput }) => {
  const elOverlay = useRef();

  const closeModal = (evt) => {
    if (evt.target === elOverlay.current) {
      closeModalP();
      setClearInput();
    }
  };

  useEffect(() => {
    function handleCloseModal(evt) {
      if (evt.code === "Escape") {
        closeModalP(false);
        setClearInput();
      }
    }

    if (modal) {
      window.addEventListener("keyup", handleCloseModal);
    }
    return () => window.removeEventListener("keyup", handleCloseModal);
  }, [modal, setClearInput]);

    useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        closeModalP(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [closeModalP]);
  return (
    <div
      ref={elOverlay}
      onClick={closeModal}
      className={`modal_overlay position-fixed top-0 start-0 w-100 h-100 pt-2 pb-2 d-flex align-items-center justify-content-center ${
        !modal && `d-none`
      }`}
    >
      <div
        style={{ width: "400px" }}
        className="modal_overlay_main mh-100 overflow-auto"
      >
        <div className="modal_overlay_header">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M30 14.5347H21.4703L28.857 10.2693L27.3922 7.73067L20.0045 11.996L24.2684 4.60867L21.7306 3.14333L17.4658 10.5288V2H14.5342V10.5297L10.2694 3.14333L7.73062 4.60867L11.9945 11.9941L4.60873 7.73067L3.14295 10.2693L10.5297 14.5347H2V17.4663H10.5288L3.14295 21.7316L4.6078 24.2703L11.9955 20.0049L7.73062 27.3923L10.2684 28.8576L14.5342 21.4712V30H17.4658V21.2883L21.809 28.8109L24.1891 27.4371L19.845 19.9125L27.3903 24.2693L28.8561 21.7307L21.4703 17.4653H29.9991V14.5347H30ZM16 19.9853C15.4767 19.9854 14.9585 19.8823 14.475 19.6821C13.9915 19.4818 13.5522 19.1882 13.1821 18.8181C12.812 18.448 12.5184 18.0086 12.3181 17.525C12.1178 17.0414 12.0147 16.523 12.0146 15.9995C12.0145 15.476 12.1175 14.9577 12.3178 14.474C12.518 13.9904 12.8114 13.5509 13.1814 13.1807C13.5514 12.8105 13.9907 12.5168 14.4741 12.3164C14.9576 12.116 15.4758 12.0129 15.9991 12.0128C17.0559 12.0127 18.0696 12.4325 18.817 13.18C19.5644 13.9275 19.9843 14.9414 19.9845 15.9986C19.9846 17.0558 19.5649 18.0698 18.8176 18.8175C18.0704 19.5651 17.0569 19.9852 16 19.9853Z"
                fill="#625DF5"
              />
            </svg>
          </div>
          <div>
            <button
              onClick={() => {
                closeModalP(false);
                setClearInput();
              }}
              className="modal_overlay_X"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="modal_content">{children}</div>
      </div>
    </div>
  );
};