import React, {useEffect, useState} from "react";
import {useApiServer} from "../ApiServer";

export const StudentInfoProgramDetails = ({role, detail, setDetailProp, setAlert}) => {
    const {getData} = useApiServer();
    const [countries, setCountries] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setIsDisabled(
            (role === "ROLE_ADMIN")
            || (role === "ROLE_OPERATOR" && detail.consultantId)
        )
    }, []);

    useEffect(() => {
        getData("country/all")
            .then(r => setCountries(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    }, []);

    const handleOnChange = (e, prop) => {
        const {value} = e.target;
        setDetailProp(prevDetail => ({
            ...prevDetail,
            [prop]: value
        }));
    };

    return (
        <>
            <form className="students_forms" action="#" method="post">
                <h5 className="students_forms_title mt-3 mb-0">Program details</h5>
                <div className="students_forms_height">
                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">Degree</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label" htmlFor="">
                                Degree
                                <select
                                    className="header_select"
                                    id=""
                                    name=""
                                    disabled={isDisabled}
                                    value={detail.degree}
                                    onChange={(e) => handleOnChange(e, "degree")}
                                >
                                    <option selected={detail.degree == null} disabled={true} className="header_option"
                                            value="">
                                        Degree
                                    </option>
                                    <option selected={detail.degree === "BACHELOR"} className="header_option"
                                            value="BACHELOR">
                                        Bachelor
                                    </option>
                                    <option selected={detail.degree === "MASTER"} className="header_option"
                                            value="MASTER">
                                        Master
                                    </option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">Program type</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label">
                                Program type
                                <input
                                    placeholder="Program type"
                                    className="students_form_inputs"
                                    type="text"
                                    name="program-type"
                                    value={detail.type || ""}
                                    disabled={isDisabled}
                                    onChange={(e) => handleOnChange(e, "type")}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">University name</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label">
                                University name
                                <textarea
                                    placeholder="description"
                                    className="students_form_inputs"
                                    rows="5"
                                    name="program-type"
                                    value={detail.description || ""}
                                    disabled={isDisabled}
                                    onChange={(e) => handleOnChange(e, "description")}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">Intake</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label">
                                Season
                                <input
                                    placeholder="Season"
                                    className="students_form_inputs"
                                    type="text"
                                    name="season"
                                    value={detail.intake || ""}
                                    disabled={isDisabled}
                                    onChange={(e) => handleOnChange(e, "intake")}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">Select country</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label" htmlFor="">
                                Select country
                                <select
                                    className="header_select"
                                    id=""
                                    name=""
                                    disabled={isDisabled}
                                    value={detail.countryId}
                                    onChange={(e) => handleOnChange(e, "countryId")}
                                >
                                    <option selected={detail.countryId == null} disabled value="">
                                        Country
                                    </option>
                                    {countries.map(c => (
                                        <option key={c.id} className="header_option" value={c.id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="students_forms_box1 mt-2 d-flex justify-content-between align-items-center">
                        <h6 className="students_forms_boxTitle">Country Insurance</h6>
                        <div className="students_forms_inputBox mx-auto">
                            <label className="students_forms_label" htmlFor="">
                                Select country
                                <select
                                    className="header_select"
                                    id=""
                                    name=""
                                    disabled={isDisabled}
                                    value={detail.countryInsuranceId}
                                    onChange={(e) => handleOnChange(e, "countryInsuranceId")}
                                >
                                    <option selected={detail.countryId == null} disabled value="">
                                        Country
                                    </option>
                                    {countries.map(c => (
                                        <option key={c.id} className="header_option" value={c.id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
