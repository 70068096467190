import React, {useEffect, useState} from "react";
import {NavLink, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import HomeLogo from "../../Img/home-logo.svg";
import StudentLogo from "../../Img/students-logo.svg";
import Chat from "../../Img/Chat.svg";
import ReportLogo from "../../Img/Report.svg";
import LogoMark from "../../Img/Logomark.svg";
import LogoTarget from "../../Img/TargetCRM.png";
import Setting from "../../Img/settings.svg";
import LogOut from "../../Img/log-out-logo.svg";
import {Settings} from "../General/Settings/Settings";
import {Home} from "./Home/Home";
import {Students} from "./Students/Students";
import {ChatPage} from "../General/Chat/ChatPage";
import {Report} from "../General/Report/Report";
import {useAuth} from "../../Hooks/useAuth";
import {Error, Login} from "../index";
import DefaultImg from "../../Img/DefaultWorker.png";
import {Sticky} from "../../components/Sticky/Sticky";
import {Kanban} from "../General/Kanban/Kanban";
import KanbanLogo from "../../Img/kanban.svg";
import {Feedback} from "../General/Feedback";
import {useApiServer} from "../General/ApiServer";
import FeedbackLogo from "../../Img/feedbackLogo.svg"

export const Consultant = ({admin, setAdmin, notifications, setAlert}) => {
    const {getData} = useApiServer()
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const [existsFeedback, setExistsFeedback] = useState(false)
    const {token} = useAuth();
    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        if (!token) {
            navigate("/register");
        }
    }, [token, navigate, notifications]);

    useEffect(() => {
        if (token) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await getData("feedback/exists-new-feedback")
                    setExistsFeedback(response.data);
                } catch (error) {
                    setAlert({status: "error", message: "Not found data"});
                }
            }, 20000);

            return () => clearInterval(intervalId);
        }
    }, [token]);

    return (
        <div className="d-flex">
            <Routes>
                <Route path="*" element={<Error/>}/>
                <Route path="/register" element={<Login/>}/>
                <Route
                    path="/"
                    element={
                        <AdminSiteBar
                            toggle={toggle}
                            isOpen={isOpen}
                            setOpen={setOpen}
                            admin={admin}
                            notifications={notifications}
                            setAdmin={setAdmin}
                            existsFeedback={existsFeedback}
                        />
                    }
                >
                    <Route
                        path="/"
                        element={<Home isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                    />
                    <Route path="/kanban" element={<Kanban isOpen={isOpen} setAlert={setAlert}/>}/>
                    <Route
                        path="/students"
                        element={<Students isOpen={isOpen} setOpen={setOpen} notifications={notifications}
                                           setAlert={setAlert}/>}
                    />
                    <Route
                        path="/chat"
                        element={<ChatPage isOpen={isOpen} setOpen={setOpen} notifications={notifications} setAlert={setAlert}/>}
                    />
                    <Route path="/report" element={<Report isOpen={isOpen} setAlert={setAlert}/>}/>
                    <Route path="/feedback" element={<Feedback
                        setExistsFeedback={setExistsFeedback}
                        isOpen={isOpen}
                        role={"ROLE_CONSULTANT"}
                        setAlert={setAlert}
                    />}/>
                    <Route path="/settings" element={<Settings setAdminP={setAdmin} setAlert={setAlert}/>}/>
                </Route>
            </Routes>
        </div>
    );
};

export const AdminSiteBar = ({isOpen, toggle, admin: {fullName, photo}, notifications, setAdmin, existsFeedback}) => {
    const navigate = useNavigate();
    const {token, setToken} = useAuth();
    const [notification, setNotification] = useState([]);

    useEffect(() => {

        const x = () => {
            setNotification(notifications)
        }

        return () => x();
    }, [notifications]);

    const handleLogout = () => {
        setToken(null);
        setAdmin(null)
        navigate("/register");
    };
    return (
        <>
            <div
                className="d-flex admin"
                style={{padding: isOpen ? "0 0 0 19%" : "0 0 0 5%"}}
            >
                <div style={{width: isOpen ? "19%" : "5%"}} className="sidebar">
                    <div>
            <span className="sitebar_div" onClick={toggle}>
              <img
                  style={{display: isOpen ? "0px block" : "5px none"}}
                  src={LogoMark}
                  alt={"Logo mark"}
              />
              <img
                  style={{display: isOpen ? "block" : "none"}}
                  src={LogoTarget}
                  alt={"Logo target"}
              />
            </span>
                        <ul className="sitebar_list">
                            <li className="sitebar_item">
                                <NavLink
                                    to={token ? "/" : "/register"}
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={HomeLogo} alt={"Home"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Home
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/kanban" className="link" activeclassName="active">
                                    <img src={KanbanLogo} alt={"Kanban"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Lead
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/students"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={StudentLogo} alt={"Student img"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Students
                                    </p>
                                </NavLink>
                            </li>

                            <li className="sitebar_item">
                                <NavLink
                                    to="/chat"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={Chat} alt={"Chat"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Chat {notifications.length > 0 && <span
                                        style={{
                                            marginLeft: "120px",
                                            background: "#e58686",
                                            width: "30px",
                                            height: "30px",
                                            display: "inline-block",
                                            padding: "3px 9px",
                                            border: "#f60505 solid 1px",
                                            borderRadius: "50%"
                                        }}
                                        className={"side-bar-notification-count"}>{notifications.length}</span>}
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/feedback"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={FeedbackLogo} alt={"Feedback img"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Feedback
                                        <span
                                            style={{
                                                color: "red",
                                                visibility: existsFeedback ? "visible" : "hidden",
                                                fontSize: "11px"
                                            }}
                                        >
                                             (new)
                                        </span>
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/report" className="link" activeclassName="active">
                                    <img src={ReportLogo} alt={"Report"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Report
                                    </p>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar_bottom">
                        <div className="sidebar_bottom_top">
                            <p style={{pointerEvents: "none"}}>
                                <Sticky isOpen={isOpen}/>
                            </p>
                            <NavLink className="link" activeclassName="active" to="/settings">
                                <img src={Setting} alt={"Setting"}/>
                                <p style={{display: isOpen ? "block" : "none", margin: "0"}}>
                                    Settings
                                </p>
                            </NavLink>
                        </div>
                        <div className="sidebar_bottom_bot">
                            <div>
                                <img
                                    style={{marginRight: isOpen ? "12px" : "0"}}
                                    src={photo ? `https://target-crm-backend-api.uz/api/v1/attach/open/` + photo : DefaultImg}
                                    alt={"Avatar"}
                                />
                                <span style={{display: isOpen ? "block" : "none"}}>
                  <p>{fullName}</p>
                  <p>Consultant</p>
                </span>
                            </div>
                            <NavLink
                                onClick={handleLogout}
                                className={"sidebar__logout"}
                                to={token ? "/" : "/register"}
                                style={{display: isOpen ? "block" : "none"}}
                            >
                                <img src={LogOut} alt="Logout"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet/>
        </>
    );
};
