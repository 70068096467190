import styled from "styled-components";
import ChatLogo from "../../../Img/message-chat.svg";
import {useEffect} from "react";
import {StudentStatus} from "../../Status/StudentStatus";

export const ConsultantChatListTable = ({chats, role, setStudentId, notifications}) => {

    const header = [
        "Surname, Name",
        "Status",
        "Contract ID",
        (role === "ROLE_CONSULTANT") ? "Registrar name" : "Consultant name",
        "",
    ];

    useEffect(() => {
        isHaveInclude()
    }, [notifications]);

    const isHaveInclude = () => {

        const chatRooms = new Set(chats.map(chat => chat.roomId));

        for (const notification of notifications) {
            if (chatRooms.has(notification.room)) {
                const chatIndex = chats.findIndex(chat => chat.roomId === notification.room);
                if (chatIndex !== -1) {
                    const chat = chats.splice(chatIndex, 1)[0];
                    chats.unshift(chat);
                }
            }
        }
    }


    const getLength = (id) => {
        return notifications.filter(item => item.room === id).length;
    };

    return (
        <>
            <div className="table_header">
                <h3>Chats</h3>
                <p>{`${chats.length} chats`}</p>
            </div>
            <Table>
                <thead>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                </thead>
                <tbody>
                {chats.map((chat) => (
                    <>
                        <TR
                            style={{cursor: "pointer"}}
                            onClick={() => setStudentId(chat.student.id)}>
                            <TD>
                                <span style={{display: "flex", alignItems: "center"}}>
                                    {chat.student.name ? chat.student.name : ""}
                                    {" "}
                                    {chat.student.surname ? chat.student.surname : ""}
                                    {!(chat.student.name || chat.student.surname) ? "-- -- --" : ""}
                                </span>
                            </TD>
                            <TD>
                                <StudentStatus role={role} type={chat.student.status}/>
                            </TD>
                            <TD>
                                {chat.student.contractNumber || "-- -- --"}
                            </TD>
                            <TD>
                                {((role === "ROLE_CONSULTANT") ? chat.student.registrarName : chat.student.consultantName) || "-- -- --"}
                            </TD>
                            <TD>
                                {
                                    getLength(chat.roomId) === 0
                                        ?
                                        <img src={ChatLogo} alt="Chat"/>
                                        :
                                        <span
                                            style={{
                                                backgroundColor: "rgb(229, 134, 134)",
                                                display: "inline-block",
                                                width: "max-content",
                                                padding: "1px 13px",
                                                border: "#F00 1px solid",
                                                borderRadius: "19px",
                                                fontWeight: "bolder",
                                            }}
                                        >{`* ${getLength(chat.roomId)} new messages`}</span>
                                }
                            </TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    padding: 16px 24px;
    border-bottom: 1px solid #eaecf0;
`;
