import React, {useEffect, useRef, useState} from "react";
import Board, {moveCard, moveColumn} from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import "./KanbanList.scss";
import {useApiServer} from "../../pages/General/ApiServer";
import CommentLogo from "../../Img/comment-logo.svg"
import ArchiveLogo from "../../Img/archive-logo.svg"

const initialBoard = {
    columns: [],
};

const LOCAL_STORAGE_KEY = "kanban_columns_order";

export const KanbanList = ({dataAll, handleApplicationId, searchTerm, isArchive, setAlert}) => {
    const {putData} = useApiServer();
    const [text] = useState("Note: ");
    const textareaRef = useRef(null);

    const [controlledBoard, setBoard] = useState(initialBoard);

    const sendReqForChangePosition = (oldC, newC, appId, oldP, newP) => {
        const data = {
            oldCanbanId: oldC,
            newCanbanId: newC,
            applicationId: appId,
            oldPosition: oldP,
            newPosition: newP,
        };

        putData("applications/change-position", data).then((r) => console.log(r));
    };

    const toggleArchive = (id) => {
        putData(`applications/change-archive/${id}/${!isArchive}`)
            .then(() => {
                const updatedBoard = {...controlledBoard};
                updatedBoard.columns = updatedBoard.columns.map((column) => {
                    column.cards = column.cards.filter((card) => card.id !== id);
                    return column;
                });
                setBoard(updatedBoard);
                setAlert({status: "success", message: `${isArchive ? "Unarchived" : "Archived"}`})
            })
            .catch((err) => {
                setAlert({status: "error", message: `${isArchive ? "Not Unarchived" : "Not Archived"}`})
            });
    };


    useEffect(() => {
        if (dataAll && Array.isArray(dataAll)) {
            let columnsOrder = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
            let orderedColumns = columnsOrder.length
                ? columnsOrder.map((id) => dataAll.find((column) => column.id === id)).filter(Boolean)
                : dataAll;

            const formattedBoard = {
                columns: orderedColumns.map((column) => ({
                    id: column.id,
                    title: column.title,
                    backgroundColor: column.backgroundColor,
                    cards: column.cards.map((card) => ({
                        id: card.id,
                        title: card.title,
                        description: card.description,
                        comment: card.comment,
                    })),
                })),
            };
            setBoard(formattedBoard);
        }
    }, [dataAll]);

    function handleColumnMove(_column, source, destination) {
        const updatedBoard = moveColumn(controlledBoard, source, destination);
        setBoard(updatedBoard);

        const newOrder = updatedBoard.columns.map((column) => column.id);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newOrder));
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [text]);

    function handleCardMove(_card, source, destination) {
        sendReqForChangePosition(
            source.fromColumnId,
            destination.toColumnId,
            _card.id,
            source.fromPosition,
            destination.toPosition
        );
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
    }


    const filteredBoard = {
        columns: controlledBoard.columns.map((column) => ({
            ...column,
            cards: column.cards.filter(
                (card) =>
                    card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    card.description.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        })),
    };

    const CustomCard = ({card}) => {
        const [inputVal, setInputVal] = useState(card.comment || "");
        const [showInput, setShowInput] = useState(false);

        const inputRef = useRef(null);

        const handleChange = (e) => {
            console.log(e.target.value);
            setInputVal(e.target.value);
        };

        const handleSubmit = async (event) => {
            event.preventDefault();

            const data = {
                id: card.id,
                comment: inputVal,
            };
            try {
                await putData(`applications/update-comment`, data).then((r) =>
                    console.log(r)
                );

                const updatedBoard = {...controlledBoard};
                updatedBoard.columns = updatedBoard.columns.map((column) => {
                    column.cards = column.cards.map((c) => {
                        if (c.id === card.id) {
                            return {...c, comment: inputVal};
                        }
                        return c;
                    });
                    return column;
                });
                setBoard(updatedBoard);

                setShowInput(false);
            } catch (error) {
                console.error("Error sending comment:", error);
            }
        };

        const toggleInput = () => {
            setShowInput(!showInput);
        };

        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowInput(false);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                setShowInput(false);
            }
        };

        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
                document.removeEventListener("keydown", handleKeyDown);
            };
        }, []);

        return (
            <div
                className="custom-card"
            >
                <div style={{cursor: "pointer", width: "170%"}}>
                    <h4
                        onClick={() => handleApplicationId(card.id)}
                        className="card__title"
                    >
                        {card.title || "-- -- --"}
                    </h4>
                    <p
                        onClick={() => handleApplicationId(card.id)}
                        className="card__text"
                    >
                        {card.description || "-- -- --"}
                    </p>
                </div>
                <div style={{height: "100%", width: "45%"}}>
                    <div
                        className="input-group mb-3"
                        onClick={toggleInput}
                    >
                    <span
                        className="input-group-text js-spanjon"
                        style={{
                            border: "none",
                            background: "none",
                            height: "10px",
                            cursor: "pointer",
                        }}
                    >
                        <img src={CommentLogo} alt={"Comment Logo"}/>
                    </span>
                    </div>
                    <div
                        style={{marginTop: "20px"}}
                        className="input-group mb-3"
                        onClick={() => toggleArchive(card.id)}
                    >
                    <span
                        className="input-group-text js-spanjon"
                        style={{
                            border: "none",
                            background: "none",
                            height: "10px",
                            cursor: "pointer",
                        }}
                    >
                        <img src={ArchiveLogo} alt={"Archive logo"}/>
                    </span>
                    </div>
                </div>
                {showInput && (
                    <div
                        ref={inputRef}
                        className={`form-floating ${showInput ? "textarea-animation" : ""}`}
                        style={{
                            width: "238px",
                            color: "white",
                            marginTop: "5px",
                            borderRadius: "5px",
                            position: "absolute",
                            zIndex: 1000,
                            opacity: 1,
                            left: 0,
                        }}
                    >
                        <svg
                            className="tri"
                            width={10}
                            height={10}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 9"
                            fill="none"
                        >
                            <path
                                d="M1.92894 8.51471C1.03803 8.51471 0.591868 7.43757 1.22183 6.8076L7.29289 0.736529C7.68342 0.346004 8.31658 0.346005 8.70711 0.736529L14.7782 6.8076C15.4081 7.43757 14.962 8.51471 14.0711 8.51471L1.92894 8.51471Z"
                                fill="#101828"
                            />
                        </svg>
                        <form onSubmit={handleSubmit}>
                            <textarea
                                ref={textareaRef}
                                rows={4}
                                cols={4}
                                onChange={handleChange}
                                type="text"
                                className="form-control textarea__form"
                                value={inputVal}
                                placeholder="Write a comment"
                                style={{
                                    width: "100%",
                                    padding: "12px",
                                    background: "#101828",
                                    color: "white",
                                    border: "none",
                                    resize: "none",
                                    position: "absolute",
                                    left: "0px", // Ensure the textarea is aligned properly
                                    top: "32px",
                                }}
                            />
                            <button className="btn__ok" type="submit">
                                OK
                            </button>
                        </form>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Board
            className="kanban_div"
            onCardDragEnd={handleCardMove}
            onColumnDragEnd={handleColumnMove}
            renderCard={(card) => <CustomCard card={card}/>}
            renderColumnHeader={({title}) => (<div className="title__kanba">{title.split("?")[0]}</div>)}
        >
            {filteredBoard}
        </Board>

    );
};
