import {useEffect, useState} from "react";
import {NavLink, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import HomeLogo from "../../Img/home-logo.svg";
import StudentsLogo from "../../Img/students-logo.svg";
import EmployeeLogo from "../../Img/employee-logo.svg";
import ControlLogo from "../../Img/control-logo.svg";
import TelegramBotLogo from "../../Img/TelegramBotLogo.svg";
import ApplicationLogo from "../../Img/application-logo.svg";
import LogoMark from "../../Img/Logomark.svg";
import LogoTarget from "../../Img/Target CRM.svg";
import Setting from "../../Img/settings.svg";
import ReportLogo from "../../Img/Report.svg";
import LogOut from "../../Img/log-out-logo.svg";
import AttendanceLogo from "../../Img/attendance.png";
import SmsLogo from "../../Img/mail-01.svg";
import "./admin.css";
import {Settings} from "../General/Settings/Settings";
import {Report} from "../General/Report/Report";
import {Application, Control, Employee, Error, Home, Login, Students,} from "../index";
import {ControlBranch, ControlCanban, ControlCountry} from "./Control";
import {useAuth} from "../../Hooks/useAuth";
import DefaultImg from "../../Img/DefaultWorker.png";
import {Sticky} from "../../components/Sticky/Sticky";
import EmployeeStudent from "./Employee/EmployeeStudent";
import {Attendance} from "./Attendance";
import {TelegramBot} from "./TelegramBot";
import {useApiServer} from "../General/ApiServer";
import {Sms} from "./sms";

export const Admin = ({admin, setAdmin, setAlert}) => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const [existsSuggestion, setExistsSuggestion] = useState(false)
    const [existsFeedback, setExistsFeedback] = useState(false)
    const {token} = useAuth();
    const {getData} = useApiServer()
    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        if (!token) {
            navigate("/register");
        }
    }, [token, navigate]);

    useEffect(() => {
        if (token) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await getData("suggestion/exists-not-read")
                    setExistsSuggestion(response.data);
                } catch (error) {
                    console.error("Error fetching Telegram bot alert:", error);
                }
            }, 20000); // Every 20 seconds

            return () => clearInterval(intervalId); // Clear interval when component unmounts
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await getData("feedback/exists-new-feedback")
                    setExistsFeedback(response.data);
                } catch (error) {
                    console.error("Error fetching Telegram bot alert:", error);
                }
            }, 20000); // Every 20 seconds

            return () => clearInterval(intervalId); // Clear interval when component unmounts
        }
    }, [token]);


    return (
        <>
            <div className="d-flex bigBoxs">
                <Routes>
                    <Route path="*" element={<Error/>}/>
                    <Route path="/register" element={<Login/>}/>
                    <Route
                        path="/"
                        element={
                            <AdminSiteBar
                                toggle={toggle}
                                isOpen={isOpen}
                                setOpen={setOpen}
                                admin={admin}
                                existsSuggestion={existsSuggestion}
                                existsFeedback={existsFeedback}
                            />
                        }
                    >
                        <Route
                            path="/"
                            element={<Home isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/students"
                            element={<Students isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/employee"
                            element={<Employee isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/employee/:id"
                            element={<EmployeeStudent isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/control/*"
                            element={<Control isOpen={isOpen} setOpen={setOpen}/>}
                        >
                            <Route index element={<ControlBranch setAlert={setAlert} isOpen={isOpen}/>}/>
                            <Route path="country" element={<ControlCountry setAlert={setAlert} isOpen={isOpen}/>}/>
                            <Route
                                path="application/*"
                                element={<ControlCanban isOpen={isOpen} setAlert={setAlert}/>}
                            />
                        </Route>
                        <Route
                            path="/application/*"
                            element={<Application isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        ></Route>
                        <Route
                            path="/report"
                            element={<Report isOpen={isOpen} setOpen={setOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/attendance"
                            element={<Attendance isOpen={isOpen}/>}
                        />
                        <Route
                            path="/tg_bot"
                            element={
                                <TelegramBot
                                    isOpen={isOpen}
                                    existsSuggestion={existsSuggestion}
                                    setExistsSuggestion={setExistsSuggestion}
                                    existsFeedback={existsFeedback}
                                    setExistsFeedback={setExistsFeedback}
                                    setAlert={setAlert}
                                />}
                        />
                        <Route
                            path="/sms"
                            element={
                                <Sms isOpen={isOpen} setAlert={setAlert}/>}
                        />
                        <Route
                            path="/settings"
                            element={<Settings isOpen={isOpen} setOpen={setOpen} setAdminP={setAdmin} setAlert={setAlert}/>}
                        />
                    </Route>
                </Routes>
                {/* <Sticky /> */}
            </div>
        </>
    );
};

export const AdminSiteBar = ({isOpen, toggle, admin: {fullName, photo}, existsSuggestion, existsFeedback}) => {
    const navigate = useNavigate();
    const {token, setToken} = useAuth();

    const handleLogout = () => {
        setToken(null);
        navigate("/register");
    };
    return (
        <>
            <div
                className="d-flex admin"
                // className={darkMode ? "dark-mode" : "light-mode"}
                style={{padding: isOpen ? "0 0 0 19%" : "0 0 0 5%"}}
            >
                <div style={{width: isOpen ? "19%" : "5%"}} className="sidebar">
                    <div>
            <span className="sitebar_div" onClick={toggle}>
              <img
                  style={{display: isOpen ? "0px block" : "5px none"}}
                  src={LogoMark}
                  alt={"Logo mark"}
              />
              <img
                  style={{display: isOpen ? "block" : "none"}}
                  src={LogoTarget}
                  alt={"Logo target"}
              />
            </span>
                        <ul className="sitebar_list">
                            <li className="sitebar_item">
                                <NavLink
                                    to={token ? "/" : "/register"}
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={HomeLogo} alt={"Logo Home"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Home
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/students"
                                    className="link"
                                >
                                    <img src={StudentsLogo} alt={"Logo Student"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Students
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/employee"
                                    className="link"
                                >
                                    <img src={EmployeeLogo} alt={"Logo Employee"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Employee
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/control" className="link">
                                    <img src={ControlLogo} alt={"Logo Control"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Control
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/application" className="link">
                                    <img src={ApplicationLogo} alt={"Logo Application"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Application
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/report"
                                    className="link"
                                >
                                    <img src={ReportLogo} alt={"Report"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Report
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/attendance"
                                    className="link"
                                >
                                    <img src={AttendanceLogo} alt={"Attendance"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Attendance
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/tg_bot" className="link">
                                    <img src={TelegramBotLogo} alt={"Logo Telegram bot"}/>
                                    <p
                                        style={{
                                            display: isOpen ? "block" : "none"
                                        }}
                                        className="link_text"
                                    >
                                        Telegram bot
                                        <span
                                            style={{
                                                color: "red",
                                                visibility: (existsSuggestion || existsFeedback) ? "visible" : "hidden",
                                                fontSize: "11px"
                                            }}
                                        >
                                             (new)
                                        </span>
                                    </p>
                                </NavLink>

                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/sms" className="link">
                                    <img src={SmsLogo} alt={"Logo SMS"}/>
                                    <p
                                        style={{
                                            display: isOpen ? "block" : "none"
                                        }}
                                        className="link_text"
                                    >
                                        Sms Service
                                    </p>
                                </NavLink>

                            </li>
                        </ul>
                    </div>
                    <div className="sidebar_bottom">
                        <div className="sidebar_bottom_top">
                            <p style={{pointerEvents: "none"}}>
                                <Sticky isOpen={isOpen}/>
                            </p>
                            <NavLink className="link" activeclassName="active" to="/settings">
                                <img src={Setting} alt={"Logo Setting"}/>
                                <p
                                    className="settings__text"
                                    style={{display: isOpen ? "block" : "none", margin: "0"}}
                                >
                                    Settings
                                </p>
                            </NavLink>
                        </div>
                        <div className="sidebar_bottom_bot">
                            <div>
                                <img
                                    style={{marginRight: isOpen ? "12px" : "0"}}
                                    src={
                                        photo
                                            ? "https://target-crm-backend-api.uz/api/v1/attach/open/" +
                                            photo
                                            : DefaultImg
                                    }
                                    alt={"Admin img"}
                                />
                                <span style={{display: isOpen ? "block" : "none"}}>
                  <p>{fullName}</p>
                  <p>Admin</p>
                </span>
                            </div>
                            <NavLink
                                onClick={handleLogout}
                                className={"sidebar__logout"}
                                to={token ? "/" : "/register"}
                                style={{display: isOpen ? "block" : "none"}}
                            >
                                <img src={LogOut} alt={"Logo logout"}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet/>
        </>
    );
};
