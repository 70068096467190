import React from "react";
import "./card.scss";
import Pin from "../../../Img/thumbtack-solid.svg";

export const Card = ({isOpen, cardImg, stat, isPin, setPinFunc}) => {
    const adminRole = localStorage.role;
    return (
        <li
            style={{
                width: isOpen ? "22%" : "265px",
                margin: "0 26px 0 0",
                backgroundColor: isPin === stat.id ? "#0000000a" : "white",
                cursor: "pointer",
            }}
            className="home_card"
        >
            {isPin === stat.id && (
                <img className="card__pin" src={Pin} alt="pin img"/>
            )}
            <div>
                <img
                    onClick={
                        stat.id !== "Students" && stat.id !== "just"
                            ? () => setPinFunc(stat.id)
                            : undefined
                    }
                    src={cardImg}
                    alt={stat.name}
                />
                <p>{stat.name}</p>
            </div>
            <p>{`${stat.countOfStudent} ${adminRole !== "ROLE_ADMIN" ? "students" : ""}`}</p>
        </li>
    );
};
