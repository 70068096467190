import React, { useRef, useState } from "react";
import "./modal.scss";
import "./modalJ.css";
import { useApiServer } from "../../pages/General/ApiServer";

export const ModalStartWork = ({ isOpen, onClose }) => {
    const [first, setFirst] = useState("");
    const [second, setSecond] = useState("");
    const [third, setThird] = useState("");
    const [fourth, setFourth] = useState("");
    const { putData } = useApiServer();

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const fourthRef = useRef(null);

    const handleChange = (e) => {
        if (e.target.name === "first") {
            setFirst(e.target.value);
            if (e.target.value === "") {
                return
            }
            if (secondRef.current) {
                secondRef.current.focus();
            }
        } else if (e.target.name === "second") {
            setSecond(e.target.value);
            if (e.target.value === "") {
                return
            }
            if (thirdRef.current) {
                thirdRef.current.focus();
            }
        } else if (e.target.name === "third") {
            setThird(e.target.value);
            if (e.target.value === "") {
                return
            }
            if (fourthRef.current) {
                fourthRef.current.focus();
            }
        } else if (e.target.name === "fourth") {
            setFourth(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if the time is provided
        if (!first || !second || !third || !fourth) {
            alert("Please enter a time.");
            return;
        }

        const time = first + second + ":" + third + fourth;

        // Ensure time is in 24-hour format
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!timeRegex.test(time)) {
            alert("Invalid time format. Please enter a valid time.");
            return;
        }

        // Submit the data
        putData("attendance/update", { entryTime: time })
            .then((response) => {
                console.log("Success:", response);
                onClose();
            })
            .catch((err) => {
                console.log("Error:", err);
            });
    };

    return (
        <div
            className={`modal_overlay position-fixed top-0 start-0 w-100 h-100 pt-2 pb-2 d-flex align-items-center justify-content-center ${
                !isOpen ? "d-none" : ""
            }`}
        >
            <div
                style={{ width: "600px", height: "380px" }}
                className="modal_overlay_main mh-100 overflow-auto"
            >
                <div className="modal_overlay_header mt-4 mx-4">
                    <p style={{ textAlign: "center", fontSize: "35px", fontWeight: "500" }}>
                        Bugun ishga nechanchi soatda kelganingizni kiriting!
                    </p>
                </div>
                <div className="modal_content">
                    <form
                        onSubmit={handleSubmit}
                        style={{ flexWrap: "wrap", alignItems: "justify-content", textAlign: "center" }}
                    >
                        <label style={{
                            display: "flex",
                            margin: "0 auto",
                            justifyContent: "center",
                        }}>
                            <input
                                style={{
                                    width: "50px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                }}
                                className="add_modal_input mt-4 mb-2"
                                name="first"
                                ref={firstRef}
                                value={first}
                                onChange={handleChange}
                                required
                            />
                            <input
                                style={{
                                    width: "50px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    marginLeft: "5px",
                                }}
                                className="add_modal_input mt-4 mb-2"
                                name="second"
                                ref={secondRef}
                                value={second}
                                onChange={handleChange}
                                required
                            />
                            <h1 style={{
                                fontWeight: "bold",
                                fontSize: "45px",
                                marginLeft: "5px",
                                marginTop: "14px",
                            }}>:</h1>
                            <input
                                style={{
                                    width: "50px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    marginLeft: "5px",
                                }}
                                className="add_modal_input mt-4 mb-2"
                                name="third"
                                ref={thirdRef}
                                value={third}
                                onChange={handleChange}
                                required
                            />
                            <input
                                style={{
                                    width: "50px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    marginLeft: "5px",
                                }}
                                className="add_modal_input mt-4 mb-2"
                                name="fourth"
                                ref={fourthRef}
                                value={fourth}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button className="add_modal_button mt-5" type="submit">
                            Enter
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
