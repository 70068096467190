import styled from "styled-components";
import {StudentStatus} from "../../Status/StudentStatus";

export const ConsultantStudentList = ({
                                          studentList,
                                          totalStudent,
                                          handleStudentId,
                                          role
                                      }) => {
    const header = [
        "Full Name",
        "Status",
        "Phone number",
        "Degree",
        "Contract ID",
        "Debt status",
        "Registrars",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Students</h3>
                <p>{`${totalStudent} students`}</p>
            </div>
            <Table>
                <thead>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                </thead>
                <tbody>
                {studentList.map(({contractNumber, degree, id, isDebt, name, phone, registrarName, status, surname}) => (
                    <>
                        <TR
                            key={id}
                            onClick={() => handleStudentId(id)}
                            style={{cursor: "pointer"}}
                        >
                            <TD>
                                <span style={{display: "flex", alignItems: "center"}}>
                                    {name || ""}
                                    {" "}
                                    {surname || ""}
                                    {!(name || surname) ? "-- -- --" : ""}
                                </span>
                            </TD>
                            <TD>
                                <StudentStatus type={status} role={role}/>
                            </TD>
                            <TD>{phone || "-- -- --"}</TD>
                            <TD>{degree || "-- -- --"}</TD>
                            <TD style={{textAlign: "center"}}>
                                {contractNumber || "-- -- --"}
                            </TD>
                            <TD>
                                <StudentStatus type={isDebt ? "DEBT" : "PAID"} role={role}/>
                            </TD>
                            <TD>{registrarName || "-- -- --"}</TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    padding: 16px 24px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
`;
