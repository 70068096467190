import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "./ListTable.scss";
import DefaultWorker from "../../Img/DefaultWorker.png";
import OpenLinkLogo from "../../Img/open-link.svg"
import {StudentStatus} from "../Status/StudentStatus";
import DeleteLogo from "../../Img/delete-86.svg"
import UpdateLogo from "../../Img/pencil-58.svg"
import InstagramLogo from "../../Img/instagram-logo.svg";
import TgLogo from "../../Img/telegram-logo.svg";
import {Box} from "@mui/material";

export const AdminUserList = ({employeeList}) => {
    const header = ["Name and Surname", "Status", "Role"];

    useEffect(() => {

    }, [employeeList]);

    return (
        <>
            <div className="table_header">
                <h3>Employee list</h3>
                <p>{`${employeeList.length} employee`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                <tbody>
                {employeeList.map(({fullName, isOnline, photo, role}) => (
                    <>
                        <TR className="list__tr">
                            <TD>
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img
                        className="logo_student"
                        width={40}
                        src={
                            photo
                                ? "https://target-crm-backend-api.uz/api/v1/attach/open/" +
                                photo
                                : DefaultWorker
                        }
                        alt="Img"
                    />{" "}
                      <strong style={{marginLeft: "30px"}}>
                      {fullName ? fullName : "-- -- --"}
                    </strong>
                  </span>
                            </TD>
                            <TD>
                                {isOnline || "-- -- --"}
                                {/* {employee.isOnline ?
                                    <img src={Online} alt=""/>
                                    :
                                    <img src={Offline} alt=""/>
                                }*/}
                            </TD>
                            <TD>
                                {role === "ROLE_ADMIN" && "Admin"}
                                {role === "ROLE_REGISTER" && "Register"}
                                {role === "ROLE_CONSULTANT" && "Consultant"}
                                {role === "ROLE_OPERATOR" && "Operator"}
                                {role === "" && "--/--/--"}
                            </TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export const BranchTable = ({branchList, updateModal, deleteBranchFunc}) => {
    const header = ["Branch name", "Description", ""];

    return (
        <>
            <div className="table_header">
                <h3>Branch</h3>
                <p>{`${branchList.length} branch`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                <tbody>
                {branchList.map((d) => (
                    <>
                        <TR>
                            <TD
                                style={{cursor: "pointer"}}
                                onClick={() => updateModal(d)}
                            >
                                {d.name}
                            </TD>
                            <TD
                                style={{cursor: "pointer"}}
                            >
                                {d.description}
                            </TD>
                            <TD>
                                {d.employeeCount === 0 ? (
                                    <svg
                                        style={{cursor: "pointer"}}
                                        onClick={() => deleteBranchFunc(d.id)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    `${d.employeeCount} employee`
                                )}
                            </TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export const CountryTable = ({
                                 countryList,
                                 openModalFunc,
                                 sendReqForDelete,
                             }) => {
    const header = ["Country name", "Required documents", ""];

    return (
        <>
            <div className="table_header">
                <h3>Country</h3>
                <p>{`${countryList.length} country`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {countryList.map((d) => (
                    <>
                        <TR>
                            <TD
                                onClick={() => openModalFunc(d)}
                                style={{cursor: "pointer"}}
                            >
                <span style={{display: "flex", alignItems: "center"}}>
                  <strong style={{marginLeft: "30px"}}>
                    {d.name ? d.name : "not available"}
                  </strong>
                </span>
                            </TD>
                            <TD onClick={() => openModalFunc(d)}>
                                {d.requiredDocumentList
                                    .map((item, index) => (index > 0 ? `, ${item}` : item))
                                    .join("")}
                            </TD>
                            <TD>
                                {d.studentCount === 0 ? (
                                    <svg
                                        style={{cursor: "pointer"}}
                                        onClick={() => sendReqForDelete(d.id)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    `${d.studentCount} students`
                                )}
                            </TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const AdminStudentList = ({
                                     studentList,
                                     totalCount,
                                     handleStudentId,
                                     role
                                 }) => {
    const header = [
        "Surname, Name",
        "Status",
        "Degree",
        "Contract ID",
        "Debt status",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Students</h3>
                <p>{`${totalCount} students`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {studentList.map(({contractNumber, degree, id, isDebt, name, status, surname}) => (
                    <>
                        <TR
                            style={{cursor: "pointer"}}
                            key={id}
                            onClick={() => handleStudentId(id)}
                            className="stu__tr"
                        >
                            <TD>
                                {name ? name : ""}
                                {" "}
                                {surname ? surname : ""}
                                {name === null && surname === null ? "-- -- --" : ""}
                            </TD>
                            <TD>
                                <StudentStatus type={status} role={role}/>
                            </TD>
                            <TD>{degree ? degree : "-- -- --"}</TD>
                            <TD>
                                {contractNumber ? contractNumber : "-- -- --"}
                            </TD>
                            <TD>
                                <StudentStatus type={isDebt ? "DEBT" : "PAID"} role={role}/>
                            </TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const AdminStudentListForSms = ({
                                           studentList,
                                           totalCount,
                                           handleStudentId,
                                           role,
                                           tickList,
                                           setTickList
                                       }) => {
    const header = [
        "Surname, Name",
        "Status",
        "Degree",
        "Contract ID",
        "Debt status",
    ];

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        if (checked) {
            if (name === "ALL") {
                setTickList(studentList.map(item => item.id))
            } else {
                setTickList((prevList) => [...prevList, name]);
            }
        } else {
            if (name === "ALL") {
                setTickList([])
            } else {
                setTickList((prevList) => prevList.filter((item) => item !== name));
            }
        }
    };

    return (
        <>
            <div className="table_header">
                <h3>Students</h3>
                <p>{`${totalCount} students`}</p>
            </div>
            <Table>
                <TR>
                    <TH>
                        All
                        <input
                            name={"ALL"}
                            type={"checkbox"}
                            onChange={handleCheckboxChange}
                        />
                    </TH>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {studentList.map(({contractNumber, degree, id, isDebt, name, status, surname}) => (
                    <>
                        <TR
                            style={{cursor: "pointer"}}
                            key={id}
                            className="stu__tr"
                        >
                            <TD>
                                <input
                                    name={id}
                                    type={"checkbox"}
                                    checked={tickList.includes(id)}
                                    onChange={handleCheckboxChange}
                                />
                            </TD>
                            <TD onClick={() => handleStudentId(id)}>
                                {name ? name : ""}
                                {" "}
                                {surname ? surname : ""}
                                {name === null && surname === null ? "-- -- --" : ""}
                            </TD>
                            <TD onClick={() => handleStudentId(id)}>
                                <StudentStatus type={status} role={role}/>
                            </TD>
                            <TD onClick={() => handleStudentId(id)}>{degree ? degree : "-- -- --"}</TD>
                            <TD onClick={() => handleStudentId(id)}>
                                {contractNumber ? contractNumber : "-- -- --"}
                            </TD>
                            <TD onClick={() => handleStudentId(id)}>
                                <StudentStatus type={isDebt ? "DEBT" : "PAID"} role={role}/>
                            </TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const AdminSmsHistoryList = ({
                                        list,
                                        totalCount,
                                        handleStudentId
                                    }) => {
    const header = [
        "Surname, Name",
        "Status",
        "Phone",
        "Sms status",
        "Send date",
        "Template name",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Students</h3>
                <p>{`${totalCount} students`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {list.map(({id, status, template, student, createdDate}) => (
                    <>
                        <TR
                            style={{cursor: "pointer"}}
                            key={id}
                            onClick={() => handleStudentId(student.id)}
                            className="stu__tr"
                        >
                            <TD>
                                {student.name ? student.name : ""}
                                {" "}
                                {student.surname ? student.surname : ""}
                                {student.name === null && student.surname === null ? "-- -- --" : ""}
                            </TD>
                            <TD>
                                <StudentStatus type={student.status} role={"ROLE_ADMIN"}/>
                            </TD>
                            <TD>{student.phone ? student.phone : "-- -- --"}</TD>
                            <TD>
                                <StudentStatus type={status} role={"ROLE_ADMIN"}/>
                            </TD>
                            <TD>{createdDate ? createdDate : "-- -- --"}</TD>
                            <TD>
                                {template.name ? template.name : "-- -- --"}
                            </TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const SmsTemplateList = ({
                                    templateList,
                                    updateFun,
                                    postDelete,
                                    getAllFunc,
                                    setAlert
                                }) => {
    const header = [
        "Template",
        "Action"
    ];
    const withList = [
        "85%",
        "15%",
    ];

    const [confirm, setConfirm] = useState("");

    const deleteRequest = () => {
        if (confirm.length < 5) return

        postDelete(`sms-template?id=${confirm}`)
            .then((r) => {
                getAllFunc()
                setAlert({status: "success", message: "Deleted successfully!"});
            })
            .catch((err) => setAlert({status: "error", message: "Not deleted"}))
        setConfirm("")
    }


    return (
        <>
            <div className="table_header">
                <h3>Templates</h3>
                <p>{`${templateList.length} students`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH style={{width: `${withList[index]}`}} key={index}>{h}</TH>
                    ))}
                </TR>
                {templateList.map(({template, name, id}) => (
                    <>
                        <TR
                            style={{cursor: "pointer"}}
                            key={id}
                            className="stu__tr"
                        >
                            <TD>
                                <span style={{fontWeight: "bold"}}>{name}</span>
                                <br/>
                                {template}
                            </TD>
                            <TD style={{display: "flex"}}>
                                {
                                    confirm === id
                                        ?
                                        <div>
                                            <p>Confirm delete</p>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    marginTop: "4px",
                                                    '& .btn-no:hover': {backgroundColor: 'red'},
                                                    '& .btn-yes:hover': {backgroundColor: 'green'}
                                                }}
                                            >
                                                <button
                                                    className={"btn-no"}
                                                    onClick={() => setConfirm("")}
                                                    style={{
                                                        border: "1px solid red",
                                                        padding: "0 10px",
                                                        borderRadius: "7px"
                                                    }}>No
                                                </button>
                                                <button
                                                    className={"btn-yes"}
                                                    onClick={() => deleteRequest()}
                                                    style={{
                                                        border: "1px solid green",
                                                        padding: "0 10px",
                                                        borderRadius: "7px"
                                                    }}
                                                >Yes
                                                </button>
                                            </Box>
                                        </div>
                                        :
                                        <>
                                            <img
                                                onClick={() => updateFun(id, name, template)}
                                                style={{height: "17px"}}
                                                src={UpdateLogo}
                                                alt={"Update"}
                                            />
                                            <img
                                                onClick={() => setConfirm(id)}
                                                style={{height: "28px", marginTop: "-5px", marginLeft: "10px"}}
                                                src={DeleteLogo}
                                                alt={"Delete"}
                                            />
                                        </>

                                }

                            </TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const ControlCanbanTable = ({
                                       openModalFunc,
                                       canbanList,
                                       sendReqForDelete,
                                   }) => {
    const header = ["Name", "Assigns", ""];

    return (
        <>
            <div className="table_header">
                <h3>Leads</h3>
                <p>{`${canbanList.length} leads`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {canbanList.map((item) => (
                    <>
                        <TR>
                            <TD
                                onClick={() => openModalFunc(item)}
                                style={{cursor: "pointer"}}
                            >
                <span style={{display: "flex", alignItems: "center"}}>
                  <strong style={{marginLeft: "30px"}}>
                    {item.name ? item.name : "not available"}
                  </strong>
                </span>
                            </TD>
                            <TD onClick={() => openModalFunc(item)}>
                                {`${item.studentsCount} users`}
                            </TD>
                            <TD>
                                {item.studentsCount === 0 ? (
                                    <svg
                                        style={{cursor: "pointer"}}
                                        onClick={() => sendReqForDelete(item.id)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                            stroke="#475467"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    "X"
                                )}
                            </TD>
                        </TR>
                    </>
                ))}
                {/* </tbody> */}
            </Table>
        </>
    );
};

export const ApplicationNewTable = ({
                                        applicationList,
                                        tickList,
                                        setTickList,
                                    }) => {
    const header = [

        "Name",
        "Age",
        "Phone number",
        "TG username",
        "Degree",
        "English level",
        "Country",
        "Platform"
    ];

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        if (checked) {
            if (name === "ALL") {
                setTickList(applicationList.map(item => item.id))
            } else {
                setTickList((prevList) => [...prevList, name]);
            }
        } else {
            if (name === "ALL") {
                setTickList([])
            } else {
                setTickList((prevList) => prevList.filter((item) => item !== name));
            }
        }
    };

    return (
        <>
            <div className="table_header">
                <h3>Application</h3>
                <p>{`${applicationList.length} application`}</p>
            </div>
            <Table>
                <TR>
                    <TH>
                        All
                        <input
                            name={"ALL"}
                            type={"checkbox"}
                            onChange={handleCheckboxChange}
                        />
                    </TH>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {applicationList.map((item) => (
                    <>
                        <TR>
                            <TD>
                                <input
                                    name={item.id}
                                    type={"checkbox"}
                                    checked={tickList.includes(item.id)}
                                    onChange={handleCheckboxChange}
                                />
                            </TD>
                            <TD style={{cursor: "pointer"}}>
                <span style={{display: "flex", alignItems: "center"}}>
                  <strong>
                    {item.name
                        ? item.name + " " + item.surname
                        : "not available"}
                  </strong>
                </span>
                            </TD>
                            <TD>{item.age}</TD>
                            <TD>{item.phone}</TD>
                            <TD>{item.tgUsername}</TD>
                            <TD>{item.degree}</TD>
                            <TD>{item.englishLevel}</TD>
                            <TD>{item.country}</TD>
                            <TD><img src={item.platform === "Telegram" ? TgLogo : InstagramLogo} alt={"Platform logo"}/></TD>
                        </TR>
                    </>
                ))}
                {/* </tbody> */}
            </Table>
        </>
    );
};

export const ApplicationOldTable = ({applicationList}) => {
    const header = [
        "Name",
        "Age",
        "Phone number",
        "Degree",
        "English level",
        "Assigned",
        "Country",
        "Platform"
    ];

    return (
        <>
            <div className="table_header">
                <h3>Application</h3>
                <p>{`${applicationList.length} application`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {applicationList.map((item) => (
                    <>
                        <TR>
                            <TD style={{cursor: "pointer"}}>
                <span style={{display: "flex", alignItems: "center"}}>
                  <strong style={{marginLeft: "30px"}}>
                    {item.name || item.surname
                        ? item.name + " " + item.surname
                        : "--/--/--"}
                  </strong>
                </span>
                            </TD>
                            <TD>{item.age}</TD>
                            <TD>{item.phone}</TD>
                            <TD>{item.degree}</TD>
                            <TD>{item.englishLevel}</TD>
                            <TD>
                                {item.operator.fullName
                                    ? item.operator.fullName
                                    : item.operator.phone}
                            </TD>
                            <TD>{item.country}</TD>
                            <TD><img src={item.platform === "Telegram" ? TgLogo : InstagramLogo} alt={"Platform logo"}/></TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const ConsultantHomeList = ({studentList, totalCount}) => {
    const header = ["Full name", "Degree", "phone", "Country"];

    return (
        <>
            <div className="table_header">
                <h3>New Students</h3>
                <p>{`${totalCount} students`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                {studentList.map((student) => (
                    <>
                        <TR className="stu__tr">
                            <TD style={{cursor: "pointer"}}>
                <span style={{display: "flex", alignItems: "center"}}>
                  <strong
                      className="students__text"
                      style={{marginLeft: "30px"}}
                  >
                    {student.name || student.surname
                        ? student.name + " " + student.surname
                        : "--/--/--"}
                  </strong>
                </span>
                            </TD>
                            <TD>{student.degree || "--/--/--"}</TD>
                            <TD>{student.phone || "--/--/--"}</TD>
                            <TD>{student.country || "--/--/--"}</TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const ReportTable = ({students}) => {
    const header = ["Full name", "Phone", "Contract ID", "Degree", "Country"];

    return (
        <>
            <div className="table_header">
                <h3>Student list</h3>
                <p>{`${students.length} employee`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                <tbody>
                {students.map(({contractId, country, degree, fullName, phone}) => (
                    <>
                        <TR className="list__tr">
                            <TD>{fullName || "-- -- --"}</TD>
                            <TD>{phone || "-- -- --"}</TD>
                            <TD>{contractId || "-- -- --"}</TD>
                            <TD>{degree || "-- -- --"}</TD>
                            <TD>{country || "-- -- --"}</TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export const SuggestionList = ({
                                   suggestionList,
                                   totalElements
                               }) => {
    const header = [

        "Name",
        "TG username",
        "Phone number",
        "Message",
    ];

    const withList = [
        "15%",
        "15%",
        "15%",
        "55%",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Suggestion</h3>
                <p>{`${totalElements} suggestion`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH style={{width: `${withList[index]}`}}
                            key={index}>{h}</TH>
                    ))}
                </TR>
                {suggestionList.map((item) => (
                    <>
                        <TR style={{backgroundColor: item.read ? "#F9FAFB" : "#F3DEF6"}}>
                            <TD style={{cursor: "pointer"}}>
                                {item.fullName ? item.fullName : "-- -- --"}
                            </TD>
                            <TD>{item.username ? item.username : "-- -- --"}</TD>
                            <TD>{item.phone ? item.phone : "-- -- --"}</TD>
                            <TD style={{whiteSpace: "wrap"}}>{item.suggestion ? item.suggestion : "-- -- --"}</TD>
                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

export const FeedbackList = ({
                                 feedbackList,
                                 totalElements,
                                 openModalFunc
                             }) => {
    const header = [

        "Name",
        "TG username",
        "Phone number",
        "Operator",
        "Consultant",
        "Registrar",
        "Message",
    ];

    const withList = [
        "15%",
        "13%",
        "10%",
        "13%",
        "13%",
        "13%",
        "23%",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Feedback</h3>
                <p>{`${totalElements} feedback`}</p>
            </div>
            <Table>
                <TR>
                    {header.map((h, index) => (
                        <TH style={{width: `${withList[index]}`}}
                            key={index}>{h}</TH>
                    ))}
                </TR>
                {feedbackList.map((item) => (
                    <>
                        <TR style={{backgroundColor: item.read ? "#F9FAFB" : "#F3DEF6"}}>
                            <TD style={{cursor: "pointer"}}>
                                <span style={{display: "flex", alignItems: "center"}}>
                                    {item.student.name || ""}
                                    {" "}
                                    {item.student.surname || ""}
                                    {!(item.student.name || (item.student.surname)) ? "-- -- --" : ""}
                                    <img onClick={() => openModalFunc(item.student.id)} src={OpenLinkLogo}
                                         alt="Open Link"
                                         style={{height: "12px", width: "12px", marginLeft: "5px"}}/>
                                </span>
                            </TD>
                            <TD>{item.username ? item.username : "-- -- --"}</TD>
                            <TD>{item.student.phone ? item.student.phone : "-- -- --"}</TD>
                            <TD>{item.student.operatorName ? item.student.operatorName : "-- -- --"}</TD>
                            <TD>{item.student.consultantName ? item.student.consultantName : "-- -- --"}</TD>
                            <TD>{item.student.registrarName ? item.student.registrarName : "-- -- --"}</TD>
                            <TD style={{
                                width: "23%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                {item.feedback ? item.feedback : "-- -- --"}
                            </TD>

                        </TR>
                    </>
                ))}
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    padding: 16px 24px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
`;
