import React, {useEffect, useState} from "react";
import "./telegramBot.scss";
import styled from "styled-components";
import {FeedbackList, SuggestionList,} from "../../../components/ListTable/ListTable";
import {useApiServer} from "../../General/ApiServer";
import Pagination from "@mui/material/Pagination";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";

export const TelegramBot = ({isOpen, existsSuggestion, setExistsSuggestion, existsFeedback, setExistsFeedback, setAlert}) => {
    const {getData, postData} = useApiServer();
    const [currentMenu, setCurrentMenu] = useState("SUGGESTION")
    const [listSuggestion, setListSuggestion] = useState([])
    const [listFeedback, setListFeedback] = useState([])
    const [totalElementsS, setTotalElementsS] = useState(0);
    const [totalElementsF, setTotalElementsF] = useState(0);
    const [size] = useState(10);
    const [page, setPage] = useState(1);
    const [studentId, setStudentId] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handlePageChange = (value) => {
        setPage(value);
        getAllData(value, size, currentMenu);
    };

    const handleMenuChange = (value) => {
        getAllData(1, 10, value);
        setCurrentMenu(value)
    };

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const fakeFunc = () => {
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
    };

    const getAllData = (pageReq = 1, sizeReq = 10, menu = "SUGGESTION") => {

        const endpoint = menu === "SUGGESTION" ? "suggestion" : "feedback";

        getData(`${endpoint}?page=${pageReq - 1}&size=${sizeReq}`)
            .then((r) => {
                if (menu === "SUGGESTION") {
                    setListSuggestion(r.data.content);
                    setListFeedback([]);
                    setTotalElementsS(r.data.totalElements);
                    setTotalElementsF(0);
                    markAsReadSuggestion()
                } else if (menu === "FEEDBACK") {
                    setListSuggestion([])
                    setListFeedback(r.data.content)
                    setTotalElementsS(0);
                    setTotalElementsF(r.data.totalElements);
                    markAsReadFeedback()
                } else {
                    setListFeedback([])
                    setListFeedback([])
                    setTotalElementsS(0);
                    setTotalElementsF(0);
                }
            })
            .catch(() => {
                setListFeedback([])
                setListFeedback([])
                setTotalElementsS(0)
                setTotalElementsF(0)
            });

    };

    const markAsReadSuggestion = () => {
        postData("suggestion/read")
            .then(() => setExistsSuggestion(false))
            .catch(() => setAlert({status: "error", message: "Not found data"}))

    }
    const markAsReadFeedback = () => {
        postData("feedback/read")
            .then(() => setExistsFeedback(false))
            .catch(() => setAlert({status: "error", message: "Not found data"}))

    }

    useEffect(() => {
        getAllData()
    }, [currentMenu]);

    return (
        <>
            <div
                style={{width: isOpen ? "100%" : "100%"}}
                className="containerH control__box"
            >
                <div className="home_header">
                    <h2>Telegram bot</h2>
                </div>
                <div className="home_span"></div>
                <ul className="control_list">
                    <li className="control_item control_link"
                        style={{
                            color: "#667085",
                            backgroundColor: currentMenu === "SUGGESTION" ? "#fff" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("SUGGESTION")}
                    >
                        Suggestion
                        <span
                            style={{
                                fontSize: "40px",
                                marginTop: "-19px",
                                color: "red",
                                visibility: existsSuggestion ? "visible" : "hidden"
                            }}
                        >.</span>
                    </li>
                    <li className="control_item control_link"
                        style={{
                            color: "#667085",
                            backgroundColor: currentMenu === "FEEDBACK" ? "#fff" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("FEEDBACK")}
                    >
                        Feedback
                        <span
                            style={{
                                fontSize: "40px",
                                marginTop: "-19px",
                                color: "red",
                                visibility: existsFeedback ? "visible" : "hidden"
                            }}
                        >.</span>
                    </li>
                </ul>
                <div className="mt-4 d-flex justify-content-between control_table">
                    <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                        {
                            currentMenu === "SUGGESTION" ?
                                <>
                                    <SuggestionList
                                        suggestionList={listSuggestion}
                                        totalElements={totalElementsS}
                                    />
                                    <div className="pagination">
                                        <Pagination
                                            count={Math.ceil(totalElementsS / size)}
                                            size="large"
                                            page={page}
                                            onChange={(e, value) => handlePageChange(value)}
                                        />
                                    </div>
                                </>

                                :
                                <>
                                    <FeedbackList
                                        feedbackList={listFeedback}
                                        totalElements={totalElementsF}
                                        openModalFunc={handleStudentId}
                                    />
                                    <div className="pagination">
                                        <Pagination
                                            count={Math.ceil(totalElementsF / size)}
                                            size="large"
                                            page={page}
                                            onChange={(e, value) => handlePageChange(value)}
                                        />
                                    </div>
                                </>

                        }

                    </WorkerList>
                </div>
                <StudentInfoModal
                    modal={isOpenModal}
                    setModal={closeModalFunc}
                    role={"ROLE_ADMIN"}
                    getAll={fakeFunc()}
                    applicationId={null}
                    studentId={studentId}
                    notifications={[]}
                />
            </div>

        </>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /* margin-right: 32px; */
    @media (max-width: 1822px) {
        height: 63vh;
    }
    @media (max-width: 1708px) {
        height: 60vh;
    }
    @media (max-width: 1608px) {
        height: 55vh;
    }
    @media (max-width: 1508px) {
        height: 50vh;
    }
    @media (max-width: 1308px) {
        height: 46vh;
    }
`;
