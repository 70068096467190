import React, {useEffect, useState} from "react";
import "./control.scss";
import styled from "styled-components";
import {BranchTable, ControlCanbanTable, CountryTable,} from "../../../components/ListTable/ListTable";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {ModalAll} from "../../../components/Modal/ModalAll";
import {useApiServer} from "../../General/ApiServer";

export const Control = ({isOpen}) => {
    const location = useLocation();
    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH control__box"
        >
            <div className="home_header">
                <h2>Control</h2>
            </div>
            <div className="home_span"></div>
            <ul className="control_list">
                <li className="control_item">
                    <NavLink
                        to={`/control`}
                        className="control_link"
                        style={{
                            color: "#667085",
                            backgroundColor:
                                location.pathname === "/control" ? "#fff" : "#f9fafb",
                        }}
                    >
                        Branch
                    </NavLink>
                </li>
                <li className="control_item">
                    <NavLink
                        to="country"
                        className="control_link"
                        style={{
                            color: "#667085",
                            backgroundColor:
                                location.pathname === "/control/country" ? "#fff" : "#f9fafb",
                        }}
                    >
                        Country
                    </NavLink>
                </li>
                <li className="control_item">
                    <NavLink
                        to="application"
                        className="control_link"
                        style={{
                            color: "#667085",
                            backgroundColor:
                                location.pathname === "/control/application"
                                    ? "#fff"
                                    : "#f9fafb",
                        }}
                    >
                        Lead
                    </NavLink>
                </li>
            </ul>

            <Outlet/>
        </div>
    );
};

export const ControlBranch = ({isOpen, setAlert}) => {
    const {getData, postData, putData, delData} = useApiServer();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [currentBranch, setCurrentBranch] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const getAllBranches = () => {
        getData("branch/all")
            .then((r) => setBranchList(r.data))
            .catch(() => {
                setBranchList([])
                setAlert({status: "error", message: "Not found data"})
            });
    };

    useEffect(() => {
        getAllBranches();
    }, []);

    const sendReqForAdd = async (data) => {
        await postData("branch/create", data)
            .then(() => {
                setAlert({status: "success", message: "Created successfully!"});
            })
            .catch(() => {
                setAlert({status: "error", message: "Not created"});
            })
        await getAllBranches();
    };

    const sendReqForUpd = async (data) => {
        const formData = {
            name: data.name,
            description: data.description,
        };

        await putData(`branch/update/${data.id}`, formData)
            .then(() => {
                setAlert({status: "success", message: "Update successfully!"});
            })
            .catch(() => {
                setAlert({status: "error", message: "Not update"});
            })
        await getAllBranches();
    };

    const sendReqForDel = async (id) => {
        await delData(`branch/delete/${id}`)
            .then(() => {
                setAlert({status: "success", message: "Deleted successfully!"});
            })
            .catch(() => {
                setAlert({status: "error", message: "Not deleted"});
            })
        await getAllBranches();
    };

    const openModalFunc = (branch = null) => {
        setCurrentBranch(branch);
        setIsOpenModal(true);
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setCurrentBranch(null);
        setName("");
        setDescription("");
    };

    const onSave = async (branch) => {
        if (branch.id) {
            await sendReqForUpd(branch);
        } else {
            await sendReqForAdd(branch);
        }
        closeModalFunc();
    };

    useEffect(() => {
        if (currentBranch) {
            setName(currentBranch.name);
            setDescription(currentBranch.description);
        } else {
            setName("");
            setDescription("");
        }
    }, [currentBranch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSave({id: currentBranch ? currentBranch.id : null, name, description});
    };

    return (
        <>
            <div className="home_header mt-3">
                <h3 className="control_name">Branch</h3>
                <button
                    onClick={() => setIsOpenModal(true)}
                    className="control_header_button"
                >
                    Add branch
                </button>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between control_table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <BranchTable
                        branchList={branchList}
                        deleteBranchFunc={sendReqForDel}
                        updateModal={openModalFunc}
                    />
                </WorkerList>
            </div>
            <ModalAll
                modal={isOpenModal}
                closeModalP={closeModalFunc}
                setClearInput={closeModalFunc}
            >
                <h3 className="add_modal_title">
                    {currentBranch ? "Update Branch" : "Add Branch"}
                </h3>
                <form onSubmit={handleSubmit}>
                    <label className="w-100">
                        <p className="add_modal_input_text">Branch name</p>
                        <input
                            className="add_modal_input w-100"
                            name="name"
                            placeholder="Branch name"
                            type="text"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <label className="w-100">
                        <p className="add_modal_input_text mt-2">Description</p>
                        <textarea
                            className="add_modal_input w-100"
                            id="story"
                            name="description"
                            rows="5"
                            cols="30"
                            placeholder="Write description..."
                            value={description}
                            required
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </label>
                    <button className="add_modal_button" type="submit">
                        {currentBranch ? "Update" : "Add"}
                    </button>
                </form>
            </ModalAll>
        </>
    );
};

export const ControlCountry = ({isOpen, setAlert}) => {
    const {getData, postData, putData, delData} = useApiServer();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [name, setName] = useState("");
    const [requiredDocumentList, setRequiredDocumentList] = useState([]);

    const getAllCountries = () => {
        getData("country/all")
            .then((r) => setCountries(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        getAllCountries();
    }, []);

    useEffect(() => {
        if (currentCountry) {
            setName(currentCountry.name);
            setRequiredDocumentList(currentCountry.requiredDocumentList || []);
        } else {
            setName("");
            setRequiredDocumentList([]);
        }
    }, [currentCountry]);

    const sendReqForCreate = async () => {

        await postData("country/create", {name, requiredDocumentList})
            .then(() => setAlert({status: "success", message: "Created successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not created"}));

        await getAllCountries();
    };

    const sendReqForUpdate = async () => {
        await putData(`country/update/${currentCountry.id}`, {name, requiredDocumentList,})
            .then(()=>setAlert({status: "success", message: "Updated successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not updated"}))
        await getAllCountries();
    };

    const sendReqDelete = async (id) => {
        await delData(`country/delete/${id}`)
            .then(() => setAlert({status: "success", message: "Deleted successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not deleted"}));
        await getAllCountries();
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (currentCountry && currentCountry.id) {
            await sendReqForUpdate();
        } else {
            await sendReqForCreate();
        }

        closeModalFunc();
    };

    const openModalFunc = (country = null) => {
        setIsOpenModal(true);
        setCurrentCountry(country);
        if (country) {
            setName(country.name);
            setRequiredDocumentList(country.requiredDocumentList);
        }
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setCurrentCountry(null);
        setName("");
        setRequiredDocumentList([]);
    };

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        if (checked) {
            setRequiredDocumentList((prevList) => [...prevList, name]);
        } else {
            setRequiredDocumentList((prevList) =>
                prevList.filter((item) => item !== name)
            );
        }
    };

    const isDocumentRequired = (docName) => {
        return requiredDocumentList.includes(docName);
    };

    return (
        <>
            <div className="home_header mt-3">
                <h3 className="control_name">Country</h3>
                <button
                    onClick={() => setIsOpenModal(true)}
                    className="control_header_button"
                >
                    Add country
                </button>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between control_table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <CountryTable
                        countryList={countries}
                        openModalFunc={openModalFunc}
                        sendReqForDelete={sendReqDelete}
                    />
                </WorkerList>
            </div>
            <ModalAll
                modal={isOpenModal}
                closeModalP={closeModalFunc}
                setClearInput={closeModalFunc}
            >
                <h3 className="add_modal_title">Add country</h3>
                <p className="add_modal_text">Please fill in the following fields</p>
                <form onSubmit={onSave} className="add_modal_form">
                    <label>
                        <p className="add_modal_input_text">Country name</p>
                        <input
                            className="add_modal_input"
                            placeholder="Add country"
                            value={name}
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <p className="add_modal_input_text">Required documents</p>
                    <div className="d-flex justify-content-between mb-3">
                        <label className="add_modal_input_checkbox">
                            IELTS
                            <input
                                className="add_modal_input_checkbox-checked"
                                type="checkbox"
                                name="IELTS"
                                onChange={handleCheckboxChange}
                                checked={isDocumentRequired("IELTS")}
                            />
                        </label>
                        <label className="add_modal_input_checkbox">
                            Duolingo
                            <input
                                className="add_modal_input_checkbox-checked"
                                type="checkbox"
                                name="Duolingo"
                                onChange={handleCheckboxChange}
                                checked={isDocumentRequired("Duolingo")}
                            />
                        </label>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <label className="add_modal_input_checkbox">
                            CEFR
                            <input
                                className="add_modal_input_checkbox-checked"
                                type="checkbox"
                                name="CEFR"
                                onChange={handleCheckboxChange}
                                checked={isDocumentRequired("CEFR")}
                            />
                        </label>
                    </div>
                    <button
                        onClick={() => setIsOpenModal(false)}
                        className="add_modal_button"
                        type="submit"
                    >
                        Add country
                    </button>
                </form>
            </ModalAll>
        </>
    );
};

export const ControlCanban = ({isOpen, setAlert}) => {
    const {getData, postData, putData, delData} = useApiServer();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [canbans, setCanbans] = useState([]);
    const [currentCanban, setCurrentCanban] = useState();
    const [name, setName] = useState();

    const getAllCanbans = () => {
        getData("canban/all-for-admin")
            .then((response) => {
                setCanbans(response.data);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        getAllCanbans();

        if (currentCanban) {
            setName(currentCanban.name);
        } else {
            setName("");
        }
    }, []);

    const sendReqForCreate = async () => {

        await postData("canban/create", {name: name})
            .then(()=> setAlert({status: "success", message: "Created successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not created"}));
        await getAllCanbans();
    };

    const sendReqForUpdate = async () => {
        await putData(`canban/update/${currentCanban.id}`, {name: name})
            .then(() => setAlert({status: "success", message: "Updated successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not updated"}));
        await getAllCanbans();
    };

    const sendReqForDelete = async (id) => {
        await delData(`canban/delete/${id}`)
            .then(()=> setAlert({status: "success", message: "Deleted successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not deleted"}))
        await getAllCanbans();
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (currentCanban && currentCanban.id) {
            await sendReqForUpdate();
        } else {
            await sendReqForCreate();
        }

        closeModalFunc();
    };

    const openModelFunc = (canban = null) => {
        setIsOpenModal(true);
        if (canban) {
            setCurrentCanban(canban);
            setName(canban.name);
        }
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setCurrentCanban(null);
        setName("");
    };

    return (
        <>
            <div className="home_header mt-3">
                <h3 className="control_name">Lead</h3>
                <button
                    onClick={() => openModelFunc()}
                    className="control_header_button"
                >
                    Add lead
                </button>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between control_table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <ControlCanbanTable
                        openModalFunc={openModelFunc}
                        canbanList={canbans}
                        sendReqForDelete={sendReqForDelete}
                    />
                </WorkerList>
            </div>
            <ModalAll modal={isOpenModal} closeModalP={closeModalFunc}>
                <h3 className="add_modal_title">Add kanban</h3>
                <p className="add_modal_text">Please fill in the following fields</p>
                <form onSubmit={onSave} className="add_modal_form">
                    <label>
                        <p className="add_modal_input_text">Enter kanban name</p>
                        <input
                            className="add_modal_input"
                            placeholder="To do"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <button className="add_modal_button" type="submit">
                        {name ? "Update" : "Add application"}
                    </button>
                </form>
            </ModalAll>
        </>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /* margin-right: 32px; */
    @media (max-width: 1822px) {
        height: 63vh;
    }
    @media (max-width: 1708px) {
        height: 60vh;
    }
    @media (max-width: 1608px) {
        height: 55vh;
    }
    @media (max-width: 1508px) {
        height: 50vh;
    }
    @media (max-width: 1308px) {
        height: 46vh;
    }
`;
