import React, {useEffect, useRef, useState} from "react";
import "./sms.scss";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {AdminSmsHistoryList, AdminStudentListForSms, SmsTemplateList} from "../../../components/ListTable/ListTable";
import Pagination from "@mui/material/Pagination";
import {useApiServer} from "../../General/ApiServer";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";

const menuList = ["Students", "History", "Templates", "Auth"]
export const Sms = ({isOpen, setAlert}) => {
    const [currentMenu, setCurrentMenu] = useState(menuList[0])

    return (

        (currentMenu === menuList[2])
            ? <SmsTemplate isOpen={isOpen} setCurrentMenu={setCurrentMenu} setAlert={setAlert}/>
            : (currentMenu === menuList[1])
                ? <SmsHistory isOpen={isOpen} setCurrentMenu={setCurrentMenu} setAlert={setAlert}/>
                : (currentMenu === menuList[3])
                    ? <SmsAuth isOpen={isOpen} setCurrentMenu={setCurrentMenu} setAlert={setAlert}/>
                    : <SmsStudents isOpen={isOpen} setCurrentMenu={setCurrentMenu} setAlert={setAlert}/>


    );
};

export const SmsHistory = ({isOpen, setCurrentMenu, setAlert}) => {
    const {getData, postData} = useApiServer();
    const [list, setList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [size] = useState(10);
    const [page, setPage] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [search, setSearch] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [studentId, setStudentId] = useState();
    const role = "ROLE_ADMIN";

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
    };
    const requestApiByFilter = (
        pageP = 1,
        sizeP = 10,
        templateId = null,
        startDate = null,
        endDate = null,
        searchP = "",
    ) => {
        postData(
            `sms-history`,
            {
                page: pageP - 1,
                size: sizeP,
                templateId: templateId,
                startTime: startDate,
                endTime: endDate,
                search: searchP
            }
        )
            .then((res) => {
                setList(res.data.content);
                setTotalElements(res.data.totalElements);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        setSelectedTemplate(null);
        requestApiByFilter();
        requestApiGetSmsTemplate()
    }, []);

    const handleSelectChange = (event) => {
        const value = event.target.value;
        if (value === "NULL") {
            setSelectedTemplate(null)
            requestApiByFilter(page, size, null, null, null, search);
        } else {
            requestApiByFilter(page, size, value, null, null, search);
            setSelectedTemplate(value);
        }

    };

    const fakeFunc = () => {
    };

    const handleSearchChange = (event) => {
        let text = event.target.value;
        setSearch(text);
        requestApiByFilter(page, size, selectedTemplate, null, null, text)
    };

    const handlePageChange = (value) => {
        setPage(value);
        requestApiByFilter(value, size,);
    };


    const requestApiGetSmsTemplate = () => {
        getData(`sms-template`)
            .then((r) => setTemplateList(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Students</h2>
                <div>
                    <select
                        onChange={handleSelectChange}
                        className="header_select"
                    >
                        <option className="header_option" selected value={"NULL"}>
                            Filter(Template)
                        </option>
                        {templateList.map(item => (
                            <option className="header_option" value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            className="search__mode"
                            onChange={handleSearchChange}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="home_header mt-3" style={{position: "relative"}}>
                <ul className="control_list" style={{padding: "0"}}>
                    {menuList.map(item => (
                        <li className="control_item control_link"
                            style={{
                                color: "#667085",
                                backgroundColor: "Students" === item ? "#fff" : "#f9fafb",
                            }}
                            onClick={() => setCurrentMenu(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-4 d-flex justify-content-between student__table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <AdminSmsHistoryList
                        list={list}
                        totalCount={totalElements}
                        handleStudentId={handleStudentId}
                    />
                    <div className="pagination">
                        <Pagination
                            count={Math.ceil(totalElements / size)}
                            size="large"
                            page={page}
                            onChange={(e, value) => handlePageChange(value)}
                        />
                    </div>
                </WorkerList>
            </div>

            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={fakeFunc()}
                applicationId={null}
                studentId={studentId}
                notifications={[]}
            />
        </div>
    );
}

export const SmsStudents = ({isOpen, setCurrentMenu, setAlert}) => {
    const {getData, postData} = useApiServer();
    const [students, setStudents] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [size] = useState(10);
    const [page, setPage] = useState(1);
    const [isDebt, setIsDebt] = useState(5);
    const [status, setStatus] = useState("ALL");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const role = "ROLE_ADMIN";
    const [modal, setModal] = useState(false)
    const [tickList, setTickList] = useState([]);
    const [assignId, setAssignId] = useState();
    const [templateList, setTemplateList] = useState([]);
    const overlyClose = useRef();

    const requestApiBySearch = (text) => {
        getData(`student/search/${text}`)
            .then((r) => setStudents(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
        setApplicationId(null);
    };
    const requestApiByFilter = (
        pageP = 1,
        sizeP = 10,
        statusP = "ALL",
        isDebtP = 5
    ) => {
        getData(`student/get-all?page=${pageP - 1}&size=${sizeP}&status=${statusP}&isDebt=${isDebtP}`)
            .then((res) => {
                setStudents(res.data.content);
                setTotalElements(res.data.totalElements);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        setStatus("ALL");
        requestApiByFilter();
        requestApiGetTemplate()
    }, []);

    const handleSelectChange = (event) => {
        const prop = event.target.id;
        const value = event.target.value;
        if (prop === "is-debt") {
            setIsDebt(value);
            requestApiByFilter(page, size, status, value);
        }
        if (prop === "status") {
            setStatus(value);
            requestApiByFilter(page, size, value, isDebt);
        }
    };

    const fakeFunc = () => {
    };

    const handleSearchChange = (event) => {
        let text = event.target.value;

        if (text.length >= 4) {
            requestApiBySearch(text);
        } else {
            requestApiByFilter();
        }
    };

    const handlePageChange = (value) => {
        setPage(value);
        requestApiByFilter(value, size, status, isDebt);
    };

    const handleSubmit = (e) => {
        sendSmsFunc()
        e.preventDefault();
        setTickList([]);
        setAssignId(null);
        setModal(false);
    };

    const sendSmsFunc = () => {

        if (tickList.length < 1 || assignId === undefined || assignId === null) return

        postData(
            "send-sms",
            {
                ids: tickList,
                templateId: `${assignId}`
            }
        )
            .then(() => setAlert({status: "success", message: "Send successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not Send"}))
    }

    const handleRadioChange = (e) => {
        setAssignId(e.target.id);
    };

    const closeAssign = (e) => {
        if (e.target === overlyClose.current) {
            setModal(!modal);
        }
    };

    const requestApiGetTemplate = () => {
        getData(`sms-template`)
            .then((r) => setTemplateList(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Students</h2>
                <div>
                    <select
                        onChange={handleSelectChange}
                        className="header_select"
                        id="is-debt"
                    >
                        <option className="header_option" value={5}>
                            ALL
                        </option>
                        <option className="header_option" value={1}>
                            Debtor
                        </option>
                        <option className="header_option" value={0}>
                            Paid
                        </option>
                    </select>
                    <select
                        onChange={handleSelectChange}
                        className="header_select"
                        id="status"
                        name=""
                    >
                        <option className="header_option" selected disabled hidden>
                            Filter(status)
                        </option>
                        <option className="header_option" value="ALL">
                            All
                        </option>
                        <option className="header_option" value="DRAFT">
                            Draft
                        </option>
                        <option className="header_option" value="COMPLETED">
                            Complete
                        </option>
                        <option className="header_option" value="WAITING">
                            Waiting
                        </option>
                        <option className="header_option" value="SUCCESS">
                            Success
                        </option>
                        <option className="header_option" value="REJECT">
                            Reject
                        </option>
                    </select>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            className="search__mode"
                            onChange={handleSearchChange}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="home_header mt-3" style={{position: "relative"}}>
                <ul className="control_list" style={{padding: "0"}}>
                    {menuList.map(item => (
                        <li className="control_item control_link"
                            style={{
                                color: "#667085",
                                backgroundColor: "Students" === item ? "#fff" : "#f9fafb",
                            }}
                            onClick={() => setCurrentMenu(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
                <button
                    style={{
                        border: "none",
                        outline: "none",
                        // marginLeft: "90%"
                    }}
                    ref={overlyClose}
                    onClick={closeAssign}
                    className="employe_header_button"
                >
                    Send SMS
                </button>
                {modal && (
                    <Modal className={`${!modal && "d-none"}`} style={{zIndex: "100"}}>
                        <h2 style={{fontSize: "14px", lineHeight: "20px"}}>Templates</h2>
                        <form onSubmit={handleSubmit}>
                            <ModalBox>
                                {templateList.map(({template, id}) => (
                                    <ModalLabel
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px 6px",
                                            background: "#F9FAFB",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <span>{template}</span>
                                        </div>
                                        <input
                                            type="radio"
                                            name="name"
                                            id={id}
                                            onChange={handleRadioChange}
                                        />
                                    </ModalLabel>
                                ))}
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "87%",
                                        padding: "10px 134px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        background: "#7F56D9",
                                        borderRadius: "10px",
                                        color: "white",
                                        fontWeight: "600",
                                    }}
                                    hidden={assignId == null}
                                    type="submit"
                                    /*onClick={() => setModal(false)}*/
                                >
                                    Send
                                </button>
                            </ModalBox>
                        </form>
                    </Modal>
                )}
            </div>
            <div className="mt-4 d-flex justify-content-between student__table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <AdminStudentListForSms
                        studentList={students}
                        totalCount={totalElements}
                        handleStudentId={handleStudentId}
                        role={role}
                        tickList={tickList}
                        setTickList={setTickList}
                    />
                    <div className="pagination">
                        <Pagination
                            count={Math.ceil(totalElements / size)}
                            size="large"
                            page={page}
                            onChange={(e, value) => handlePageChange(value)}
                        />
                    </div>
                </WorkerList>
            </div>

            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={fakeFunc()}
                applicationId={applicationId}
                studentId={studentId}
                notifications={[]}
                setAlert={setAlert}
            />
        </div>
    );
}

export const SmsTemplate = ({isOpen, setCurrentMenu, setAlert}) => {
    const {getData, postData, putData, delData} = useApiServer();
    const [modalAdd, setModalAdd] = useState(false)
    const [templateList, setTemplateList] = useState([]);
    const overlyCloseAdd = useRef();
    const [addTemplate, setAddTemplate] = useState("")
    const [addName, setAddName] = useState("")
    const [updateId, setUpdateId] = useState("")


    useEffect(() => {
        requestApiGetTemplate()
    }, []);


    const closeAssignAdd = (e) => {
        if (e.target === overlyCloseAdd.current) {
            setModalAdd(!modalAdd);
        }
    };

    const handlePostTemplate = (e) => {
        e.preventDefault();
        setModalAdd(false);
        if (addTemplate.length < 10) return
        if (updateId.length < 3) {
            postData(
                "sms-template",
                {
                    name: `${addName}`,
                    template: `${addTemplate}`
                }
            )
                .then(() => {
                    setAlert({status: "success", message: "Created successfully!"});
                    requestApiGetTemplate()
                })
                .catch(() => setAlert({status: "error", message: "Not created"}))
        } else if (updateId.length > 5) {
            putData(
                "sms-template",
                {
                    id: `${updateId}`,
                    name: `${addName}`,
                    template: `${addTemplate}`
                }
            )
                .then(() => {
                    requestApiGetTemplate()
                    setAlert({status: "success", message: "Update successfully!"});
                })
                .catch(() => setAlert({status: "error", message: "Not update"}))
            setUpdateId("")
        }

        setAddTemplate("")
        setAddName("")
    }

    const handleUpdateFun = (id, name, template) => {
        setUpdateId(id)
        setAddName(name)
        setAddTemplate(template)
        setModalAdd(true)
    }

    const requestApiGetTemplate = () => {
        getData(`sms-template`)
            .then((r) => setTemplateList(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Templates</h2>
            </div>
            <div className="home_span"></div>
            <div className="home_header mt-3" style={{position: "relative"}}>
                <ul className="control_list" style={{padding: "0"}}>
                    {menuList.map(item => (
                        <li className="control_item control_link"
                            style={{
                                color: "#667085",
                                backgroundColor: "Templates" === item ? "#fff" : "#f9fafb",
                            }}
                            onClick={() => setCurrentMenu(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
                <button
                    style={{
                        border: "none",
                        outline: "none",
                    }}
                    ref={overlyCloseAdd}
                    onClick={closeAssignAdd}
                    className="employe_header_button"
                >
                    Add new
                </button>
                {modalAdd && (
                    <Modal className={`${!modalAdd && "d-none"}`} style={{zIndex: "100", height: "370px"}}>
                        <h2 style={{fontSize: "18px", lineHeight: "20px", fontWeight: "500"}}>Add new</h2>
                        <form onSubmit={(e) => handlePostTemplate(e)}>
                            <ModalBox
                                style={{
                                    width: "400px",
                                    height: "408px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "10px"
                                }}
                            >
                                <label htmlFor="name">Name</label>
                                <input
                                    onChange={(e) => setAddName(e.target.value)}
                                    value={addName}
                                    type="text"
                                    id={"name"}
                                    name={"template-name"}
                                    style={{
                                        border: "1px solid #EAECF0",
                                        borderRadius: "10px",
                                        padding: "10px",
                                        width: "100%",
                                    }}
                                />
                                <label htmlFor="template" style={{marginTop: "10px"}}>Description</label>
                                <textarea
                                    id={"template"}
                                    name={"addTemplate"}
                                    placeholder={"write a template ..."}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "1px solid #EAECF0",
                                        borderRadius: "10px",
                                        padding: "10px"
                                    }}
                                    value={addTemplate}
                                    onChange={(e) => setAddTemplate(e.target.value)}
                                />
                                <button
                                    style={{
                                        padding: "10px 134px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        background: "#7F56D9",
                                        borderRadius: "10px",
                                        color: "white",
                                        marginTop: "8%",
                                        marginLeft: "11%"
                                    }}
                                    // hidden={assignId == null}
                                    type="submit"
                                >
                                    Add new
                                </button>
                            </ModalBox>
                        </form>
                    </Modal>
                )}

            </div>
            <div className="mt-4 d-flex justify-content-between student__table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <SmsTemplateList
                        templateList={templateList}
                        updateFun={handleUpdateFun}
                        postDelete={delData}
                        getAllFunc={requestApiGetTemplate}
                        setAlert={setAlert}
                    />
                </WorkerList>
            </div>
        </div>
    );
}

export const SmsAuth = ({isOpen, setCurrentMenu, setAlert}) => {
    const {getData, postData} = useApiServer();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")


    useEffect(() => {
        getAllData()
    }, []);

    const getAllData = () => {
        getData("sms-auth")
            .then((r) => {
                const data = r.data
                setUsername(data.username)
                setPassword(data.password)
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}))
    }

    const requestForPost = () => {
        if (username.length < 5 || password.length < 5) return

        postData(
            "sms-auth",
            {
                username: `${username}`,
                password: `${password}`
            }
        )
            .then(() => setAlert({status: "success", message: "Created successfully!"}))
            .catch(() => setAlert({status: "error", message: "Username or password is invalid"}))

        getAllData()
    }


    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Auth</h2>
            </div>
            <div className="home_span"></div>
            <div className="home_header mt-3" style={{position: "relative"}}>
                <ul className="control_list" style={{padding: "0"}}>
                    {menuList.map(item => (
                        <li className="control_item control_link"
                            style={{
                                color: "#667085",
                                backgroundColor: "Auth" === item ? "#fff" : "#f9fafb",
                            }}
                            onClick={() => setCurrentMenu(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-4 d-flex justify-content-between student__table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <div className={"sms-auth-data-box"}>
                        <div className={"top-box"}>
                            <div className={"label-box"}>
                                <label htmlFor="">Username</label>
                                <label htmlFor="">Password</label>
                            </div>
                            <div className={"input-box"}>
                                <input value={username} onChange={(e) => setUsername(e.target.value)} type="text"
                                       placeholder={"Enter username..."}/>
                                <input value={password} onChange={(e) => setPassword(e.target.value)}
                                       className={"input-2"} placeholder={"Enter password..."}
                                       type="text"/>
                            </div>
                        </div>
                        <div className={"btn-box"}>
                            <button onClick={() => requestForPost()}>Save</button>
                        </div>

                    </div>
                </WorkerList>
            </div>
        </div>
    );
}

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 73vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 78vh;
    }
    @media (max-width: 1708px) {
        height: 76vh;
    }
    @media (max-width: 1608px) {
        height: 73vh;
    }
    @media (max-width: 1508px) {
        height: 70vh;
    }
    @media (max-width: 1308px) {
        height: 66vh;
    }
`;

const Modal = styled.div`
    position: absolute;
    right: 0;
    top: 42px;
    background-color: #f9fafb;
    padding: 16px 9px 50px 9px;
    border: 2px solid #eaecf0;
    border-radius: 10px;
`;

const ModalBox = styled.div`
    height: 300px;
    overflow-y: scroll;
    scrollbar-color: #eaecf0 transparent;
    scrollbar-width: thin;
`;

const ModalLabel = styled.label`
    display: flex;
    width: 308px;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
    margin-right: 5px;
    background-color: white !important;
    border: 2px solid #dfdfdf;
    border-radius: 10px;
`;


