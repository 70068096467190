import axios from "axios";
import styled from "styled-components";
import {useAuth} from "../../../Hooks/useAuth";
import {useEffect, useState} from "react";
import {Admin} from "../../Admin/Admin";
import {Operator} from "../../Operator/Operator";
import {Consultant} from "../../Consultant/Consultant";
import {Registrar} from "../../Registrator/Registrar";
import LoginLogo from "../../../Img/Login.png";
import {useApiServer} from "../../General/ApiServer";
import {collection, onSnapshot, query} from "firebase/firestore";
import {db} from "../../General/firebase-config";

export const Login = ({setAlert}) => {
    const {token, setToken} = useAuth();
    const [role, setRole] = useState();
    const [admin, setAdmin] = useState(null);
    const {getData} = useApiServer();
    const [notifications, setNotifications] = useState([]);
    const [darkMode] = useState(() => {
        const savedMode = localStorage.getItem("darkMode");
        return savedMode ? JSON.parse(savedMode) : false;
    });

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(darkMode));
    }, [darkMode]);

    useEffect(() => {
        if (token && admin == null) {
            getData("admin/")
                .then(res => {
                    localStorage.setItem("pin", res.data.pin || "NEW");
                    localStorage.setItem("role", res.data.role);
                    localStorage.setItem("id", res.data.id);
                    setAdmin(res.data);
                    setRole(res.data.role);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem("token")
                        window.location.reload();
                    }
                });
        }
    }, [token, getData]);

    useEffect(() => {
        if (admin) {
            if (admin.role === "ROLE_CONSULTANT" || admin.role === "ROLE_REGISTRAR") {
                const q = query(collection(db, admin.id));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let notification = [];
                    querySnapshot.forEach((doc) => {
                        notification.push({...doc.data(), id: doc.id});
                    });

                    setNotifications(() => notification);
                });
                return () => unsubscribe();

            }
        }
    }, []);

    if (token && admin) {
        switch (role) {
            case "ROLE_ADMIN":
                return <Admin admin={admin} setAdmin={setAdmin} setAlert={setAlert}/>;
            case "ROLE_OPERATOR":
                return <Operator admin={admin} setAdmin={setAdmin} setAlert={setAlert}/>;
            case "ROLE_CONSULTANT":
                return <Consultant admin={admin} setAdmin={setAdmin} notifications={notifications} setAlert={setAlert}/>;
            case "ROLE_REGISTER":
                return <Registrar admin={admin} setAdmin={setAdmin} notifications={notifications} setAlert={setAlert}/>;
            default:
                return null;
        }
    }

    const handleUserRegister = (evt) => {
        evt.preventDefault();

        const formData = {
            phone: evt.target.phone.value,
            password: evt.target.password.value,
        };

        axios
            .post("https://target-crm-backend-api.uz/api/v1/auth/login", formData, {
                headers: {
                    Authorization: `Basic ${btoa(`${formData.phone}:${formData.password}`)}`, // Basic auth requires encoding
                },
            })
            .then((res) => {
                setToken(res.data);
                setAlert({status: "success", message: "Welcome your dashboard"});
            })
            .catch(() => {
                setAlert({status: "error", message: "Phone or password is not correct"});
            });
        setAlert(null);
    };

    return (
        <Div className="d-flex regis">
            <Wrapper className="reg">
                <LoginTitle>Welcome</LoginTitle>
                <LoginText>Enter your phone number and password to login</LoginText>
                <Form onSubmit={handleUserRegister} className="form d-flex flex-column">
                    <Label htmlFor="phone">Phone number</Label>
                    <Input
                        id="phone"
                        placeholder="+998 90 123 45 67"
                        className="form-control mb-3 m-auto"
                        type="text"
                        name="phone"
                    />
                    <Label htmlFor="password">Password</Label>
                    <Input
                        id="password"
                        placeholder="Password"
                        className="form-control mb-3 m-auto"
                        type="password"
                        name="password"
                    />
                    <Button type="submit">Enter</Button>
                </Form>
                {/*{alert && <Alert status={alert.status} message={alert.message}/>}*/}
            </Wrapper>
            <Left>
                <Img src={LoginLogo}/>
            </Left>
        </Div>
    );
};

const Div = styled.div`
    margin: 0 auto;
    height: 100vh;
    width: 100vw;
`;
const Left = styled.div`
    width: 50%;
    height: 100vh;
    background-color: #f8f8f8;
`;
const Wrapper = styled.div`
    margin: auto;
    height: 55%;
    width: 330px;
    align-items: center;
    background-color: #fff;
`;
const LoginTitle = styled.h2`
    width: 334px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #101828;
    font-family: Inter, serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
`;
const LoginText = styled.p`
    padding: 0;
    text-align: center;
    margin-top: 6px;
    width: 334px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;
const Form = styled.form`
    width: 100%;
    margin-top: 32px;
`;
const Label = styled.label`
    padding: 0;
    margin-bottom: 4px;
    color: #344054;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
`;
const Input = styled.input`
    width: 330px;
    height: 46px;
    margin-bottom: 16px;
    background: #ffffff;
    padding: 8px 10px 8px 15px;
    border-radius: 5px;
    border: 1px solid #d8d6de;

    &:focus {
        border-radius: 8px;
        border: 1px solid #d6bbfb;
        background: #fff;
        box-shadow: 0 0 0 4px rgba(158, 119, 237, 0.24),
        0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
`;
const Button = styled.button`
    margin: 0;
    padding: 10px 22px;
    text-align: center;
    color: #fff;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid #7f56d9;
    background: #7f56d9;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    &:hover,
    :active {
        border-radius: 8px;
        border: 1px solid #7f56d9;
        background: #7f56d9;
        box-shadow: 0 0 0 4px rgba(158, 119, 237, 0.24),
        0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
`;
const Img = styled.img`
    width: 100%;
    height: 100%;
`;

export default Login;
