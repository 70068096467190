import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import {Card} from "../../General/Card";
import styled from "styled-components";
import BasicDateCalendar from "../../../components/DateCalendar/DateCalendar";
import {RegistrarHomeListTable} from "../../../components/ListTable/RegistratorListTable/RegistrarHomeListTable";
import {Pagination} from "@mui/material";
import "./Home.scss";
import {useApiServer} from "../../General/ApiServer";
import Draft from "../../../Img/Consultant-home-draft.svg";
import Complete from "../../../Img/Consultant-home-complate.svg";
import Repeat from "../../../Img/Consultant-home-repeat.svg";
import TotalUser from "../../../Img/Card-total-user.svg";
import {ModalStartWork} from "../../../components/Modal/ModalStartWork";

export const Home = ({isOpen, setAlert}) => {
    const {getData} = useApiServer();
    const [{numberStudentsOfRejected, numberStudentsOfSuccess, numberStudentsOfWaiting, totalStudents}, setStats] = useState({});
    const [students, setStudents] = useState([]);
    const [totalStudentCount, setTotalStudentCount] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const sendReqForGetAll = async (pageR = page) => {
        getData(`student/get-all-by-status?page=${pageR - 1}&size=10&status=R_NEW`)
            .then((r) => {
                setStudents(r.data.content);
                setTotalStudentCount(r.data.totalElements);
                setTotalPageCount(r.data.totalPages);
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        getData("stats/registrar")
            .then((r) => {
                setStats(r.data)
                sendReqForGetAll();
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}));
        getData("attendance/check-for-today")
            .then(r => {
                if (r.data === true) {
                    setShowModal(true);
                }
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}))
    }, []);

    const onClose = () => {
        setShowModal(false);
    }

    const handlePageChange = async (event, pageP) => {
        setPage(pageP);
        await sendReqForGetAll(pageP);
    };

    return (
        <div style={{width: isOpen ? "81%" : "95%"}} className="containerH">
            <div className="home_header">
                <h2>Dashboard</h2>
                <label>
                    <img src={Search} alt={"Search"}/>
                    <input type="text" name="input" placeholder="Search"/>
                </label>
            </div>
            <div className="home_span"></div>
            <ul className="home_item">
                <Card
                    cardImg={Draft}
                    stat={{
                        id: "just",
                        name: "Waiting",
                        countOfStudent: numberStudentsOfWaiting,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={Complete}
                    stat={{
                        id: "just",
                        name: "Success",
                        countOfStudent: numberStudentsOfSuccess,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={Repeat}
                    stat={{
                        id: "just",
                        name: "Rejected",
                        countOfStudent: numberStudentsOfRejected,
                    }}
                    isPin={"str"}
                />
                <Card
                    cardImg={TotalUser}
                    stat={{
                        id: "just",
                        name: "Total Student",
                        countOfStudent: totalStudents,
                    }}
                    isPin={"str"}
                />
            </ul>
            <div className="mt-4 d-flex justify-content-between student_r">
                <div style={{width: "100%"}}>
                    <WorkerList style={{width: isOpen ? "92.5%" : "92.5%"}}>
                        <RegistrarHomeListTable
                            students={students}
                            totalCount={totalStudentCount}
                        />
                        <div className="pagination ">
                            <Pagination
                                // className={`pagination home_p ${isOpen ? "open" : "closed"}`}
                                count={totalPageCount}
                                size="large"
                                page={page}
                                onChange={handlePageChange}
                            />
                        </div>
                    </WorkerList>
                </div>
                <div
                    className="calendar_op"
                    style={{
                        borderRadius: "10px",
                    }}
                >
                    <BasicDateCalendar
                        slotProps={{
                            textField: {
                                InputLabelProps: {
                                    sx: {
                                        fontSize: "1rem",
                                        color: "red",
                                        backgroundColor: "red",
                                        fontWeight: 400,
                                        "&.Mui-focused": {color: "#5F5955"},
                                    },
                                },
                                variant: "filled",
                                fullWidth: true,
                                size: "small",
                                InputProps: {
                                    style: {fontWeight: 700},
                                    disableUnderline: true,
                                },
                            },
                        }}
                    />
                </div>
            </div>
            <ModalStartWork isOpen={showModal} onClose={onClose}/>
        </div>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 80vh;
    padding-bottom: 100px;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 57vh;
    }
    @media (max-width: 1708px) {
        height: 53vh;
    }
    @media (max-width: 1608px) {
        height: 52vh;
    }
    @media (max-width: 1518px) {
        height: 48vh;
    }
    @media (max-width: 1308px) {
        height: 45vh;
    }
`;
