import React, { useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    alert: {
        position: 'fixed',
        top: theme.spacing(2),
        right: '-100%',
        transition: 'right 0.5s',
        zIndex: 9999,
        width: '300px',
    },
    show: {
        right: theme.spacing(2),
    },
    hide: {
        right: '-100%',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageAlert = ({ status, message, duration = 3000 }) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    return (
        <div className={`${classes.alert} ${visible ? classes.show : classes.hide}`}>
            <Alert severity={status}>
                {message}
            </Alert>
        </div>
    );
};

export default MessageAlert;


// import MessageAlert from "../../components/Alert/Alert";
// import { Button } from "@material-ui/core";

// const [alerts, setAlerts] = useState([]);

// const addAlert = (status, message) => {
//     setAlerts([...alerts, { status, message, id: new Date().getTime() }]);
// };



// <div>
// <h4>Universal Alert Component Example</h4>
// <Button onClick={() => addAlert("success", "This is a success message!")}>Show Success Alert</Button>
// <Button onClick={() => addAlert("error", "This is an error message!")}>Show Error Alert</Button>
// <Button onClick={() => addAlert("info", "This is an info message!")}>Show Info Alert</Button>
// <Button onClick={() => addAlert("warning", "This is a warning message!")}>Show Warning Alert</Button>
// {alerts.map((alert) => (
//     <MessageAlert key={alert.id} status={alert.status} message={alert.message} />
// ))}
// </div>

