import React, {useState} from 'react';
import InstagramPage from './pages/Instagram/InstagramPage';
import {Login} from "./pages";
import Alert from "./components/Alert/Alert";

function App() {
    const currentPath = window.location.pathname;
    const [alert, setAlert] = useState(null);

    const setAlertFunc = (message) => {
        setAlert(message);
        setTimeout(() => setAlert(null), 3000);
    }

    if (currentPath === '/Instagram') {
        return <InstagramPage/>;
    } else {
        return <>
            <Login setAlert={setAlertFunc}/>
            {alert && <Alert status={alert.status} message={alert.message}/>}
        </>
    }
}

export default App;


