import React, { useEffect, useState } from "react";

export const OperatorStudentInfoPersonalDetails = ({
  role,
  detail,
  setDetail,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(
      role === "ROLE_ADMIN" ||
        (role === "ROLE_OPERATOR" && detail.consultantId)
    );
  }, []);

  const handleOnChange = (e, prop) => {
    const { value } = e.target;

    setDetail((prevDetail) => ({
      ...prevDetail,
      [prop]: value,
    }));
  };

  return (
    <>
      <form className="students_forms" action="#" method="post">
        <h5 className="students_forms_title mt-3 mb-0">Personal details</h5>
        <div className="students_forms_height">
          <div className="students_forms_box1 mt-2 align-items-center">
            <h6 className="students_forms_boxTitle">Personal info</h6>
            <div className="students_forms_inputBox">
              <label className="students_forms_label">
                Surname
                <input
                  placeholder="John"
                  className="students_form_inputs"
                  type="text"
                  name="surname"
                  value={detail.surname || ""}
                  disabled={isDisabled}
                  onChange={(e) => handleOnChange(e, "surname")}
                />
              </label>
              <label className="students_forms_label">
                Name
                <input
                  placeholder="Doe"
                  className="students_form_inputs"
                  type="text"
                  name="name"
                  value={detail.name || ""}
                  disabled={isDisabled}
                  onChange={(e) => handleOnChange(e, "name")}
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Contact info</h6>
            <div className="students_forms_inputBox">
              <label className="students_forms_label">
                Email
                <input
                  placeholder="Email"
                  className="students_form_inputs"
                  type="email"
                  name="email"
                  value={detail.email || ""}
                  disabled={isDisabled}
                  onChange={(e) => handleOnChange(e, "email")}
                />
              </label>
              <label className="students_forms_label">
                Phone number
                <input
                  placeholder="Phone Number"
                  className="students_form_inputs"
                  type="tel"
                  name="phone"
                  minLength="9"
                  maxLength="14"
                  value={detail.phone || ""}
                  disabled={isDisabled}
                  onChange={(e) => handleOnChange(e, "phone")}
                />
              </label>
            </div>
          </div>
          <div className="students_forms_box1 mt-3 align-items-center">
            <h6 className="students_forms_boxTitle">Personal info</h6>
            <div
              className="students_forms_inputBox"
              style={{ display: "block" }}
            >
              <div className="students_forms_inputBox">
                <label className="students_forms_label">
                  Contract number
                  <input
                    placeholder="#123456"
                    className="students_form_inputs"
                    type="text"
                    name="contractNumber"
                    value={detail.contractNumber || ""}
                    disabled={isDisabled}
                    onChange={(e) => handleOnChange(e, "contractNumber")}
                  />
                </label>
                <label className="students_forms_label">
                  Total Price
                  <input
                    placeholder="12.000.000"
                    className="students_form_inputs"
                    type="number"
                    name="totalPrice"
                    value={detail.totalPrice || 0}
                    disabled={isDisabled}
                    onChange={(e) => handleOnChange(e, "totalPrice")}
                  />
                </label>
              </div>
              <div className="students_forms_inputBox">
                <label className="students_forms_label">
                  Paid
                  <input
                    placeholder="10.000"
                    className="students_form_inputs"
                    type="number"
                    name="paidPrice"
                    value={detail.paidPrice || 0}
                    disabled={isDisabled}
                    onChange={(e) => handleOnChange(e, "paidPrice")}
                  />
                </label>
                <label className="students_forms_label">
                  Debt
                  <input
                    placeholder="10.300"
                    className="students_form_inputs"
                    type="number"
                    name="debtPrice"
                    value={detail.debtPrice || 0}
                    disabled={isDisabled}
                    onChange={(e) => handleOnChange(e, "debtPrice")}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
