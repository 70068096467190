import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import {KanbanList} from "../../../components/KanbanList/KanbanList";
import "./Kanban.scss";
import {useApiServer} from "../ApiServer";
import StudentInfoModal from "../StudentInfoModal/StudentInfoModal";

export const Kanban = ({isOpen, setAlert}) => {
    const {getData} = useApiServer();
    const [dataAll, setDataAll] = useState();
    const [modal, setModal] = useState(false);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const [role, setRole] = useState("ROLE_OPERATOR");
    const [searchTerm, setSearchTerm] = useState("");
    const [button, setButton] = useState("ACTIVE");

    const closeModalFunc = () => {
        setModal(false);
        setStudentId(null);
        setApplicationId(null);
    };

    const handleApplicationId = (id = null) => {
        if (id) {
            setApplicationId(id);
        }
        setModal(true);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const x = () => {
    };

    const getAllList = (text = button) => {
        getData(`canban/all/${(text === "ARCHIVE")}`)
            .then((r) => setDataAll(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
    }

    useEffect(() => {

        getAllList()
        setRole(localStorage.getItem("role"));
    }, []);

    const handleMenuChange = (text) => {
        setButton(text)
        getAllList(text)
    }

    return (
        <>
            <div style={{width: isOpen ? "81%" : "95%"}} className="containerH kanba__box">
                <div className="home_header" style={{position: "sticky", top: 0, zIndex: 1, backgroundColor: "white"}}>
                    <h2>Students</h2>
                    <div>
                        <label>
                            <img src={Search} alt="Search"/>
                            <input placeholder="Search..." value={searchTerm} onChange={handleSearch} className="search-input"/>
                        </label>
                    </div>
                </div>
                <ul className="control_list" style={{padding: "0", marginTop: "0px"}}>
                    <li className="control_item control_link"
                        style={{
                            color: "#667085",
                            backgroundColor: button === "ACTIVE" ? "#fff" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("ACTIVE")}
                    >
                        Active
                    </li>
                    <li className="control_item control_link"
                        style={{
                            color: "#667085",
                            backgroundColor: button === "ARCHIVE" ? "#fff" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("ARCHIVE")}
                    >
                        Archive
                    </li>
                </ul>
                <div className="home_span" style={{position: "sticky", top: 0, zIndex: 1, backgroundColor: "white", marginBottom: "6px", height: "0px"}}>

                </div>
                <div>
                    <KanbanList
                        dataAll={dataAll}
                        isOpen={isOpen}
                        handleApplicationId={handleApplicationId}
                        searchTerm={searchTerm}
                        isArchive={button === "ARCHIVE"}
                        setAlert={setAlert}
                    />
                </div>
            </div>
            <StudentInfoModal
                modal={modal}
                setModal={closeModalFunc}
                role={role}
                getAll={x}
                applicationId={applicationId}
                studentId={studentId}
                setAlert={setAlert}
                showPositionChange={true}
            />
        </>
    );
};
