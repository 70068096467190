import React, {useEffect, useRef, useState} from "react";
import Plus from "../../../Img/user-plus.svg";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {ApplicationNewTable, ApplicationOldTable,} from "../../../components/ListTable/ListTable";
import "./application.css";
import "./application.scss";
import DefaultWorkerImg from "../../../Img/DefaultWorker.png";
import {useApiServer} from "../../General/ApiServer";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import axios from "axios";
import {useAuth} from "../../../Hooks/useAuth";

export const Application = ({isOpen, setAlert}) => {
    const {getData, putData} = useApiServer();
    const {token} = useAuth();
    const [applications, setApplications] = useState([]);
    const [modal, setModal] = useState(false);
    const [tickList, setTickList] = useState([]);
    const [assignId, setAssignId] = useState();
    const [operatorList, setOperatorList] = useState([]);
    const overlyClose = useRef();
    const [activeBtn, setActiveBtn] = useState("NEW");
    const [activeAssignBtn, setActiveAssignBtn] = useState("ROLE_OPERATOR");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getAllApplications = async (status = activeBtn) => {
        await getData(`applications/get-all-by-status/${status}`)
            .then((r) => {
                setApplications(r.data);
                setActiveBtn(status);
            })
            .catch(() => {
                setApplications([]);
                setActiveBtn(status);
                setAlert({status: "error", message: "Not found data"})
            });
    };

    const searchRequest = async (text) => {
        await getData(`applications/get-all-by-status/${activeBtn}/${text}`)
            .then((r) => {
                setApplications(r.data);
            })
            .catch(() => {
                setApplications([]);
                setAlert({status: "error", message: "Not found data"})
            });
    };

    const getAllOperators = (activeRole = "ROLE_OPERATOR") => {
        getData(`admin/all?role=${activeRole}`)
            .then((r) => setOperatorList(r.data))
            .catch(() => {
                setOperatorList([]);
                setAlert({status: "error", message: "Not found data"})
            });
    };

    const assignToOperator = () => {
        putData("applications/assign-to-employee", {
            applicationIdList: tickList,
            employeeId: assignId,
        })
            .then(async () => {
                await getAllApplications();
                getAllOperators();
                setTickList([]);
                setAlert({status: "success", message: "Assigned successfully!"});
            })
            .catch(() => {
                setAlert({status: "error", message: "Not assigned"})
            });
    };

    useEffect(() => {
        getAllApplications();
        getAllOperators();
    }, []);

    const closeAssign = (e) => {
        if (e.target === overlyClose.current) {
            setModal(!modal);
        }
    };

    useEffect(() => {
        function handleCloseModal(evt) {
            if (evt.code === "Escape") {
                setModal(false);
            }
        }

        if (modal) {
            window.addEventListener("keyup", handleCloseModal);
        }
        return () => window.removeEventListener("keyup", handleCloseModal);
    }, [modal]);

    const handleRadioChange = (e) => {
        setAssignId(e.target.id);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        assignToOperator();
        setTickList([]);
        setAssignId(null);
        setModal(false);
    };

    const handleSearchText = async (e) => {
        const value = e.target.value;
        if (value.length > 1) {
            await searchRequest(value);
        } else if (value.length === 0) {
            await getAllApplications();
        }
    };

    const handleRole = (activeRole) => {
        setActiveAssignBtn(activeRole)

        getAllOperators(activeRole)
    }
    const setDateRange = (update) => {
        setStartDate(update[0]);
        setEndDate(update[1]);
    };

    const downloadData = async () => {

        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

        if (formattedStartDate == null && formattedEndDate == null && tickList.length < 1) {
            alert("Please select Date or Applications")
        }

        try {

            let type = (formattedStartDate == null && formattedEndDate == null) ? "LIST" : "DAY";
            const data =
                {
                    startDate: (type === "DAY") ? formattedStartDate : null,
                    endDate: (type === "DAY") ? formattedEndDate : null,
                    applicationIds: (type === "DAY") ? null : tickList,
                    reqType: type
                };
            const response =
                await axios.post(`https://target-crm-backend-api.uz/api/v1/applications/download`, data, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                    }
                })
                    .catch(() => {
                        setAlert({status: "error", message: "Not found data"})
                    });

            setTickList([])

            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '')
                : 'application.pdf';

            const url = URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        } catch (err) {
            setAlert({status: "error", message: "Not found data"})
        }
    };

    return (
        <div
            style={{width: isOpen ? "100%" : "100%"}}
            className="containerH application"
        >
            <div className="home_header">
                <h2>Application</h2>
                <div>
                    <DatePicker
                        style={{cursor: "pointer"}}
                        className="employe_header_button-white"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        placeholderText="Select a date range"
                    />
                </div>
                <button
                    onClick={() => downloadData()}
                    style={{width: "170px", display: "flex"}}
                    className="employe_header_button-white button-hover me-0"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                            stroke="#344054"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Download
                </button>
                <div>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            className="app__inp"
                            type="text"
                            name="input"
                            placeholder="Search"
                            onChange={handleSearchText}
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <ul className="control_list">
                <li
                    className="control_item control_link"
                    style={{
                        color: "#667085",
                        backgroundColor: activeBtn === "NEW" ? "#fff" : "#f9fafb",
                    }}
                    onClick={() => getAllApplications("NEW")}
                >
                    New application
                </li>
                <li
                    className="control_item control_link"
                    style={{
                        color: "#667085",
                        backgroundColor: activeBtn === "OLD" ? "#fff" : "#f9fafb",
                    }}
                    onClick={() => getAllApplications("OLD")}
                >
                    Old application
                </li>
            </ul>
            <div className="home_header mt-3" style={{position: "relative"}}>
                <h3 className="control_name">{Application}</h3>
                {activeBtn === "NEW" && (
                    <button
                        style={{border: "none", outline: "none"}}
                        ref={overlyClose}
                        onClick={closeAssign}
                        className="employe_header_button"
                    >
                        <img src={Plus} className="mx-2" alt={"Plus icon"}/>
                        Assign
                    </button>
                )}
            </div>
            <div className="mt-4 d-flex justify-content-between bigBox">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    {activeBtn === "NEW" ? (
                        <ApplicationNewTable
                            applicationList={applications}
                            setTickList={setTickList}
                            tickList={tickList}
                        />
                    ) : (
                        <ApplicationOldTable applicationList={applications}/>
                    )}
                </WorkerList>
                {modal && (
                    <Modal className={`${!modal && "d-none"}`} style={{zIndex: "100"}}>
                        {/*<h2 style={{ fontSize: "14px", lineHeight: "20px" }}></h2>*/}
                        <ul className={"assign-button-box"}>
                            <li
                                className={(activeAssignBtn === "ROLE_OPERATOR") ? "active" : ""}
                                onClick={() => handleRole("ROLE_OPERATOR")}
                            >Operators
                            </li>
                            <li
                                className={(activeAssignBtn === "ROLE_CONSULTANT") ? "active" : ""}
                                onClick={() => handleRole("ROLE_CONSULTANT")}
                            >Consultant
                            </li>
                        </ul>
                        <form onSubmit={handleSubmit}>
                            <ModalBox>
                                {operatorList.map(({fullName, id, photo}) => (
                                    <ModalLabel
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px 6px",
                                            background: "#F9FAFB",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                className="modalls"
                                                style={{marginRight: "8px"}}
                                                src={
                                                    photo
                                                        ? "https://target-crm-backend-api.uz/api/v1/attach/open/" +
                                                        photo
                                                        : DefaultWorkerImg
                                                }
                                                alt="avatar"
                                                width={24}
                                                height={24}
                                            />
                                            <span>{fullName}</span>
                                        </div>
                                        <input
                                            type="radio"
                                            name="name"
                                            id={id}
                                            onChange={handleRadioChange}
                                        />
                                    </ModalLabel>
                                ))}
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "87%",
                                        padding: "10px 134px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        background: "#7F56D9",
                                        borderRadius: "10px",
                                        color: "white",
                                        fontWeight: "600",
                                    }}
                                    hidden={assignId == null}
                                    type="submit"
                                    /*onClick={() => setModal(false)}*/
                                >
                                    Assign
                                </button>
                            </ModalBox>
                        </form>
                    </Modal>
                )}
            </div>
        </div>
    );
};

const Modal = styled.div`
    position: absolute;
    right: 0;
    top: -33px;
    background-color: #f9fafb;
    padding: 16px 9px 50px 9px;
    border: 2px solid #eaecf0;
    border-radius: 10px;
`;

const ModalBox = styled.div`
    height: 300px;
    overflow-y: scroll;
    scrollbar-color: #eaecf0 transparent;
    scrollbar-width: thin;
`;

const ModalLabel = styled.label`
    display: flex;
    width: 308px;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
    margin-right: 5px;
    background-color: white !important;
    border: 2px solid #dfdfdf;
    border-radius: 10px;
`;

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 66vh;
    }
    @media (max-width: 1708px) {
        height: 63vh;
    }
    @media (max-width: 1608px) {
        height: 58vh;
    }
    @media (max-width: 1508px) {
        height: 55vh;
    }
    @media (max-width: 1308px) {
        height: 52vh;
    }
`;
