import React from "react";

export const StudentFeedback = ({feedbacks = [], student}) => {

    function formatDate(dateString) {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${hours}:${minutes} ${day}-${month}-${year}`;
    }

    return (
        <>
            <form className="students_forms" action="#" method="post">
                <h5 className="students_forms_title mt-3 mb-0">Feedbacks</h5>
                <div className={"feedback_employee_box"}>
                    <div
                        className={"feedback_employee"}>{`Operator: ${student.operator ? student.operator.fullName : "-- -- --"}`}</div>
                    <div
                        className={"feedback_employee"}>{`Consultant: ${student.consultant ? student.consultant.fullName : "-- -- --"}`}</div>
                    <div
                        className={"feedback_employee"}>{`Registrar: ${student.registrar ? student.registrar.fullName : "-- -- --"}`}</div>
                </div>
                <div className="students_forms_height">
                    {feedbacks.map((feedback) => (
                        <div
                            style={{
                                display: "block"
                            }}
                            className="students_forms_box1 mt-2 align-items-center">
                            <div
                                style={{
                                    width: "100%",

                                }}
                                className="students_forms_boxTitle">{feedback.message}</div>
                            <div
                                style={{
                                    width: "100%",
                                    marginLeft: "78%",
                                    fontSize: "13px"
                                }}
                            >{formatDate(feedback.createdDate)}</div>
                        </div>
                    ))}

                </div>
            </form>
        </>
    );
}