import styled from "styled-components";
import {StudentStatus} from "../../Status/StudentStatus";

export const RegistrarStudentsTable = ({
                                           students,
                                           totalCount,
                                           handleStudentId,
                                           role
                                       }) => {
    const header = [
        "Name",
        "Status",
        "Phone number",
        "Degree",
        "Consultant",
        "Country",
    ];

    return (
        <>
            <div className="table_header">
                <h3>Students</h3>
                <p>{`${totalCount} students`}</p>
            </div>
            <Table>
                <thead>
                <TR>
                    {header.map((h, index) => (
                        <TH key={index}>{h}</TH>
                    ))}
                </TR>
                </thead>
                <tbody>
                {students.map(({consultantName, country, degree, id, name, phone, status, surname}) => (
                    <>
                        <TR
                            key={id}
                            onClick={() => handleStudentId(id)}
                            style={{cursor: "pointer"}}
                        >
                            <TD>
                                {name || ""}
                                {" "}
                                {surname || ""}
                                {!(name || surname) ? "-- -- --" : ""}
                            </TD>
                            <TD>
                                <StudentStatus type={status} role={role}/>
                            </TD>
                            <TD>{phone || "-- -- --"}</TD>
                            <TD>{degree || "-- -- --"}</TD>
                            <TD>{consultantName || "-- -- --"}</TD>
                            <TD>{country || "-- -- --"}</TD>
                        </TR>
                    </>
                ))}
                </tbody>
            </Table>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    padding: 16px 24px;
    border-bottom: 1px solid #eaecf0;
`;
