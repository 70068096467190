import React, {useEffect, useState} from "react";
import {Card} from "../../General/Card";
import DebtorImg from "../../../Img/Card-deptors.svg";
import KanbanS from "../../../Img/kanbanS.svg";
import styled from "styled-components";
import BasicDateCalendar from "../../../components/DateCalendar/DateCalendar";
import {OperatorHomeTable} from "../../../components/ListTable/OperatorListTabel/OperatorHomeTable";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Home.scss";
import {Pagination} from "@mui/material";
import {useApiServer} from "../../General/ApiServer";
import {ModalStartWork} from "../../../components/Modal/ModalStartWork";

export const Home = ({isOpen, setAlert}) => {
    const {getData, putData} = useApiServer();
    const [stats, setStats] = useState([]);
    const [pin, setPin] = useState(localStorage.getItem("pin") || `NEW`);
    const [applications, setApplications] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [size] = useState(10);
    const [showModal, setShowModal] = useState(false);


    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        rtl: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    const handlePageChange = async (event, page) => {
        setPage(page);
        await sendReqForGetAll(pin, page, size);
    };

    const setPinFunc = async (id) => {
        await putData(`admin/set-pin/${id}`)
            .then(() => setAlert({status: "success", message: "Pinned successfully!"}))
            .catch(() => setAlert({status: "error", message: "Not found data"}))
        setPin(id);
        await sendReqForGetAll(id, page, size);
    };

    const sendReqForGetAll = async (idR = "NEW", pageR = 1, sizeR = 10) => {
        getData(
            `applications/get-all-by-canban-id/${idR}?page=${pageR - 1}&size=${sizeR}`
        ).then((r) => {
            setApplications(r.data.content);
            setTotalElements(r.data.totalElements);
            setTotalPage(r.data.totalPages);
        }).catch(() => setAlert({status: "error", message: "Not found data"}));
    };

    useEffect(() => {
        getData("stats/operator")
            .then((r) => setStats(r.data))
            .catch(() => setAlert({status: "error", message: "Not found data"}));
        setPin(localStorage.getItem("pin") || `NEW`);
        sendReqForGetAll(pin, 1, 10);

        getData("attendance/check-for-today")
            .then(r => {
                if (r.data === true) {
                    setShowModal(true);
                }
            })
            .catch(() => setAlert({status: "error", message: "Not found data"}))

    }, []);

    const onClose = () => {
        setShowModal(false);
    }

    return (
        <ContainerH isOpen={isOpen} className="containerH operator__home">
            <div className="home_aheader">
                <h2>Dashboard</h2>
            </div>
            <div className="home_span"></div>
            <Slider {...settings}>
                {stats.map((stat) => {
                    return (
                        <Card
                            cardImg={stat.name === "Students" ? DebtorImg : KanbanS}
                            stat={stat}
                            isPin={pin}
                            setPinFunc={setPinFunc}
                        ></Card>
                    );
                })}
            </Slider>
            <div className="mt-4 d-flex justify-content-between operatorbox">
                <WorkerList style={{width: isOpen ? "78.5%" : "78.5%"}}>
                    <OperatorHomeTable
                        isOpen={isOpen}
                        applications={applications}
                        totalElements={totalElements}
                    />
                    <div className="pagination">
                        <Pagination
                            count={totalPage}
                            size="large"
                            page={page}
                            onChange={handlePageChange}
                        />
                    </div>
                </WorkerList>
                <div
                    className="calendar_op"
                    style={{
                        borderRadius: "10px",
                    }}
                >
                    <BasicDateCalendar
                        slotProps={{
                            textField: {
                                InputLabelProps: {
                                    sx: {
                                        fontSize: "1rem",
                                        color: "red",
                                        backgroundColor: "red",
                                        fontWeight: 400,
                                        "&.Mui-focused": {color: "#5F5955"},
                                    },
                                },
                                variant: "filled",
                                fullWidth: true,
                                size: "small",
                                InputProps: {
                                    style: {fontWeight: 700},
                                    disableUnderline: true,
                                },
                            },
                        }}
                    />
                </div>
            </div>
            <ModalStartWork isOpen={showModal} onClose={onClose}/>
        </ContainerH>
    );
};

const ContainerH = styled.div`
    width: ${(props) => (props.isOpen ? "80%" : "94.5%")};
`;

const WorkerList = styled.div`
    width: 130vh;
    height: 55vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    margin-right: 32px;

    @media (max-width: 1822px) {
        height: 56vh;
    }
    @media (max-width: 1708px) {
        height: 53vh;
    }
    @media (max-width: 1608px) {
        height: 47vh;
    }
    @media (max-width: 1508px) {
        height: 43vh;
    }
    @media (max-width: 1308px) {
        height: 41vh;
    }
`;
