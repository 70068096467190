import React from "react";
import styled from "styled-components";

export const OperatorHomeTable = ({applications, totalElements}) => {
    const header = ["Full name", "Age", "Phone", "Comment"];
    return (
        <>
            <div>
                <div className="table_header">
                    <h3>Application</h3>
                    <p>{`${totalElements} applications`}</p>
                </div>
                <Table>
                    <thead>
                    <TR>
                        {header.map((h, index) => (
                            <TH key={index}>{h}</TH>
                        ))}
                    </TR>
                    </thead>
                    <tbody>
                    {applications.map((application) => (
                        <>
                            <TR>
                                <TD style={{cursor: "pointer"}}>
                                    <span style={{display: "flex", alignItems: "center"}}>
                                        <strong style={{marginLeft: "30px"}}>
                                            {application.name + " " || ""}
                                            {application.surname || ""}
                                            {!(application.name || application.surname) ? "-- -- --" : ""}
                                        </strong>
                                    </span>
                                </TD>
                                <TD>{application.age || "-- -- --"}</TD>
                                <TD>{application.phone || "-- -- --"}</TD>
                                <TD>{application.comment || "-- -- --"}</TD>
                            </TR>
                        </>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

const Table = styled.table`
    width: 100%;
`;
const TR = styled.tr`
    width: 203px;
`;
const TH = styled.th`
    padding: 12px 24px;
    color: #475467;
    font-family: Inter, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
    padding: 16px 24px;
    color: #101828;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
`;
