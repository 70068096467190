import styled from "styled-components";

export const RegistrarHomeListTable = ({ students, totalCount }) => {
  const header = ["Full name", "Degree", "phone", "Country"];

  return (
    <>
      <div className="table_header">
        <h3>NEW Students</h3>
        <p>{`${totalCount} students`}</p>
      </div>
      <Table>
        <thead>
          <TR>
            {header.map((h, index) => (
              <TH key={index}>{h}</TH>
            ))}
          </TR>
        </thead>
        <tbody>
          {students.map((student) => (
            <>
              <TR>
                <TD>
                  {student.name + " " || ""}
                  {student.surname || ""}
                  {!(student.name || student.surname) ? "-- -- --" : ""}
                </TD>
                <TD>{student.degree || "-- -- --"}</TD>
                <TD>{student.phone || "-- -- --"}</TD>
                <TD>{student.country || "-- -- --"}</TD>
              </TR>
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const Table = styled.table`
  width: 100%;
`;
const TR = styled.tr`
  width: 203px;
`;
const TH = styled.th`
  padding: 12px 24px;
  color: #475467;
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #eaecf0;
`;
const TD = styled.td`
  color: #101828;
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 24px;
  border-bottom: 1px solid #eaecf0;
`;
